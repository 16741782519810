import { getFont } from '../../helpers';
import godrejPattern from '../../assets/Pattern/godrejPattern.png';
import godrejBgImage from '../../assets/ReferralForm/Godrej.png';
import store from '../../store';
import { fabClasses, imageListClasses } from '@mui/material';

const ENV = window.reloy_env.ENV || process.env.REACT_APP_ENV;

const config = {
    clientId:
        '4757d92ecbc85bd93320870e1d15ae79788920c0cbf41abe884bfbc4980a0c08',
    id: 7,
    name: 'Godrej',
    fullName: 'Godrej Properties',
    programLogo:'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1722920175286_Placeholder',
    programName:'Godrej Ambassador',
    theme: 'godrejTheme',
    favicon: 'https://cdn.loyalie.in/63578721.jpg',
    primaryColor: '#7f7065',
    fontBody: `${getFont('GODREJ').body}`,
    fontTitle: `${getFont('GODREJ').heading}`,
    googleConfig: {
        gID: 'G-8WGS6KG77E  ',
        keywordsContent: '',
        descriptionContent: '',
        authorContent: 'Godrej Ambassador'
    },

    facebookPixelConfig: {
        id: '1189595868577078',
        metaName: '',
        metaContent: ''
    },
    webengageLic: 'in~~10a5cbb09',
    webengageAPIKey: '617f9e57-d325-4b8e-a937-033466c69b77',
    uatFacebookPixelConfig: {
        id: '',
        metaName: '',
        metaContent: ''
    },
    layouts: {
        events: 'Layout2',
        blogs: 'Layout5'
    },
    activateReferPage: false,
    webview: { coloredTheme: false },
    logoLeft: {
        name: 'godrejLogo',
        baseUrl:
            'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1704809843091_godrej%20logo',
        stickyUrl:
            'https://www.godrejproperties.com/images/logo_godrej_properties.png',
        url: 'https://www.godrejproperties.com/images/logo_godrej_properties.png',
        stickyNavBarLogoClass:
            'relative h-10 lg:h-10 xl:h-10  left-2 lg:left-8 ease-in duration-300',
        baseNavBarLogoClass:
            'relative h-10 lg:h-10 xl:h-12  left-2 lg:left-8 ease-out duration-300'
    },

    navBarConfig: {
        dropDown: true,
        font: `${getFont('GODREJ').heading}`,
        height: 'h-16 lg:h-20 ease-out duration-300',
        stickyHeight: 'h-16 lg:h-18 ease-in duration-300 	',
        mobileIconColor: 'text-primary',
        dropDownBg: 'bg-white  ',
        showLogin: true,
        baseNavBar: {
            border: false,
            borderClass: '',
            backgroundColor: 'bg-primary-content drop-shadow-md ', // default: transparent
            containerClass: ' ',
            itemClass:
                'uppercase lg:mr-4 xl:mr-7 text-black lg:text-black hover:text-primary text-[14px] lg:text-[10px] xl:text-[13px] 2xl:text-[13px]',
            dropDownContainerClass: `text-black lg:text-black hover:text-primary focus:text-primary lg:focus:shadow-outline `,
            dropDownItemClass:
                'tracking-widest hover:text-primary text-[14px] lg:text-[11px] xl:text-[13px] 2xl:text-[13px]'
        },
        stickyNavBar: {
            border: true,
            borderClass: ' ',
            backgroundColor: 'bg-primary-content drop-shadow-md ', //default: primary-content,
            containerClass: ' ', //entire Nav Bar Class (add min-w-full for godrej navbar effect)
            itemClass:
                'uppercase lg:mr-7 text-black lg:text-black hover:text-primary text-[14px] lg:text-[11px] xl:text-[13px] 2xl:text-[13px]' // Each list element in NavBar (Text color, hover color, size)
        },
        showActiveLinkColor: false
    },
    floatingButton: true,
    changePath: false,
    floaterClass: 'text-white bg-primary hover:godrej-floater-hover',
    floaterType: 'REFERRAL',
    iconColor: 'text-white',
    bannerNegativeTopMarginClass: ' ',
    scrollToTargetAdjustedValue: 60,
    clientButtonClassName: `godrej-button hover:godrej-button-hover text-sm ${
        getFont('GODREJ').heading
    }`,
    loginModal: {
        showEmployeeLogin: true,
        showSVLogin: false,
        border: 'border-b-2 border-primary',
        text: 'text-primary',
        buttonBg: 'bg-primary'
    },
    increasePageSize: true,
    showFooterRoutes: false,
    webviewRoutes: {
        home: {
            name: 'OVERVIEW',
            scrollTo: 'overview',
            path: '/',
            show: true
        },
        events: {
            name: 'CURATED EVENTS',
            path: '/events',
            component: 'Events',
            show: true
        },
        referSection: {
            show: true,
            name: 'REFER NOW',
            scrollTo: 'referral_section',
            path: '/',
            component: 'ReferAndEarn'
        }
    },
    webview: {
        textStyle: `${getFont('GODREJ').heading} uppercase font-medium`,
        iconColor: `#7f7065`,
        initialsClass: 'text-primary'
    },
    routes: {
        home: {
            name: 'OVERVIEW',
            scrollTo: 'overview',
            path: '/',
            show: true,
            components: [
                {
                    type: 'BANNER_NEW',
                    backgroundImage: 'https://cdn.loyalie.in/44189520.jpg   ',
                    backgroundImageMobile:
                        'https://cdn.loyalie.in/46943476.jpg',
                    bannerContainerClass:
                        'hero max-w-full h-[70vh] lg:h-[75vh] 2xl:h-[85vh] bg-top lg:bg-left-top ',
                    bannerItemContainerClass: 'lg:pl-10', // bannerContainerClassName:
                    //     'hero hero-content max-w-full h-[60vh]  md:h-[85vh] lg:h-[89vh] xl:h-[75vh] 2xl:h-[80vh] bg-center lg:bg-top z-10 ',
                    linearGradient: {
                        R: '127',
                        G: '112',
                        B: '101',
                        opacity: '0.1'
                    },
                    components: [
                        {
                            type: 'TEXT',
                            content: 'WELCOME TO',
                            className: `mt-36 lg:mt-48 xl:mt-64 2xl:mt-60 3xl:mt-60 text-white text-center lg:text-left text-[12px] lg:text-[17px] xl:text-[20px] 2xl:text-[24px] tracking-widest ${
                                getFont('GODREJ').heading
                            }`
                        },

                        {
                            type: 'IMAGE',
                            url: 'https://cdn.loyalie.in/69005341.png',
                            className:
                                'mx-auto lg:mx-0 w-[75%] md:w-[35%] lg:w-[30%] xl:w-[30%] 2xl:w-[27%] mt-2'
                        },
                        {
                            type: 'TEXT',
                            content: 'It’s more than a home, it’s a lifestyle.',
                            className: `text-white text-center lg:text-left text-[15px] md:text-[16px] lg:text-[15px] xl:text-[19px] 2xl:text-[20px] leading-[20px] 2xl:leading-[22px] tracking-wide lg:tracking-widest lg:leading-snug mt-2 mb-6 break-words  ${
                                getFont('GODREJ').medium
                            }`
                        },
                        {
                            type: 'BUTTON',
                            content: 'REFER NOW',
                            className: `mx-auto lg:mx-0 text-sm godrej-button hover:godrej-button-hover xl:mb-10 ${
                                getFont('GODREJ').heading
                            }`,
                            path: 'gift-and-earn',
                            onClick: 'CHANGE_PATH_AND_SCROLL',
                            scrollTo: 'referral_section'
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    id: 'overview',
                    className:
                        'flex flex-col justify-center text-center  overflow-hidden z-1',

                    components: [
                        {
                            type: 'CUSTOM',
                            className:
                                'w-[90%] xl:w-full mx-auto pt-8 lg:pt-12 xl:pt-16 pb-4 lg:pb-6 xl:pb-8 relative',
                            components: [
                                // {
                                //     type: 'IMAGE',
                                //     url: 'https://godrejprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Godrej/Images/64420770.png',
                                //     classNameContainer:
                                //         ' absolute w-[27%] 2xl:w-[23%] -top-0  -left-44 2xl:-left-36  z-2 opacity-30'
                                // },
                                // {
                                //     type: 'IMAGE',
                                //     url: 'https://godrejprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Godrej/Images/32160716.png',
                                //     position: 'center',
                                //     classNameContainer:
                                //         ' absolute w-[27%] 2xl:w-[23%] -right-44 2xl:-right-36 -bottom-0 z-2 opacity-30'
                                // },
                                {
                                    type: 'TEXT',
                                    content:
                                        'WELCOME TO THE GODREJ AMBASSADOR PROGRAM',

                                    className: `tracking-wide text-[17px] leading-[18px] lg:text-[18px] xl:text-[20px] 2xl:text-[22px] text-primary ${
                                        getFont('GODREJ').heading
                                    }`
                                },
                                {
                                    type: 'DIVIDER',
                                    className:
                                        'self-center m-auto w-[20%] lg:w-[10%] bg-primary border-none h-[4px]',
                                    classNameContainer: 'mt-3'
                                },
                                {
                                    type: 'TEXT',
                                    content:
                                        "Godrej Ambassador is an exclusive luxury membership program for homeowners at Godrej Properties. The program curates a lifestyle that goes beyond homeowning and enriches every facet of the member's life.",

                                    className: `text-secondary-focus text-[16px] xl:text-[16.5px] 2xl:text-[17px] mt-[20px] leading-[21px] lg:leading-[21px] xl:leading-[22px] 2xl:leading-[24px] mb-12 w-full md:w-[80%] lg:w-[90%] xl:w-[70%] 2xl:w-[60%]  mx-auto xl:mt-6 ${
                                        getFont('GODREJ').body
                                    }`
                                },
                                {
                                    type: 'TEXT',
                                    content: 'GET',
                                    className: `${
                                        getFont('GODREJ').heading
                                    } inline-block text-primary text-[16px] xl:text-[16.5px] 2xl:text-[20px] leading-[18px] lg:leading-[20px] 2xl:leading-[22px]`
                                },
                                {
                                    type: 'TEXT',

                                    content: ' 1%',
                                    className: `inline-block text-transparent bg-clip-text bg-gradient-to-r from-[#B58A2F] via-[#F8E196] to-[#D0AB6E]  ml-1 text-xl 2xl:text-[25px] leading-[18px] lg:leading-[20px] 2xl:leading-[22px] ${
                                        getFont('GODREJ').heading
                                    }`
                                },
                                {
                                    type: 'TEXT',

                                    content: ' REWARD AND SHARE',
                                    className: `inline-block text-primary ml-1 text-[16px] xl:text-[16.5px] 2xl:text-[20px] leading-[18px] lg:leading-[20px] 2xl:leading-[22px] ${
                                        getFont('GODREJ').heading
                                    } `
                                },
                                {
                                    type: 'TEXT',

                                    content: ' 1%',
                                    className: `inline-block text-transparent bg-clip-text bg-gradient-to-r from-[#B58A2F] via-[#F8E196] to-[#D0AB6E]  ml-1 text-xl 2xl:text-[25px] leading-[18px] lg:leading-[20px] 2xl:leading-[22px] ${
                                        getFont('GODREJ').heading
                                    } `
                                },
                                {
                                    type: 'TEXT',
                                    content: ' DISCOUNT',
                                    className: `inline-block text-primary ml-1 text-[16px] xl:text-[16.5px] 2xl:text-[20px] leading-[18px] lg:leading-[20px] 2xl:leading-[22px] ${
                                        getFont('GODREJ').heading
                                    }`
                                },
                                {
                                    type: 'CUSTOM',
                                    className:
                                        'w-full md:w-[75%] lg:w-full 2xl:w-full mx-auto lg:mt-4 z-1',
                                    components: [
                                        {
                                            type: 'ICONS_LAYOUT',

                                            infotxtClassName:
                                                ' w-[90%] md:w-[50%] lg:w-[55%]  xl:w-[60%] 2xl:w-[75%]  mx-auto l 2xl:px-10 text-[16px] xl:text-[16.5px] 2xl:text-[17px] font-Helvetica_Light  text-black font-normal leading-[21px] lg:leading-[21px] xl:leading-[22px] 2xl:leading-[24px] ',
                                            info: [
                                                {
                                                    imgUrl: 'https://godrejprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Godrej/Images/23965860.png',
                                                    infoText:
                                                        'Avail exclusive lifestyle offers & benefits'
                                                },
                                                {
                                                    imgUrl: 'https://godrejprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Godrej/Images/63869318.png',
                                                    infoText:
                                                        'Enjoy select events and experiences'
                                                },
                                                {
                                                    imgUrl: 'https://godrejprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Godrej/Images/53575022.png',
                                                    infoText:
                                                        'Experience trending lifestyle topics'
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            type: 'CAROUSEL_NEW',
                            carouselHeight: 'h-auto lg:h-[75vh]',
                            headingClass: `tracking-wide text-[17px] leading-[18px] lg:text-[18px] xl:text-[20px] 2xl:text-[22px] text-white ${
                                getFont('GODREJ').heading
                            }`,
                            bodyClass: `text-white text-[16px] xl:text-[16.5px] 2xl:text-[17px] py-5 leading-[21px] lg:leading-[21px] xl:leading-[22px] 2xl:leading-[24px]  ${
                                getFont('GODREJ').body
                            }`,
                            buttonClass: `bg-white rounded-[30px] px-4 xl:px-[30px] py-2 xl:py-[12px] text-primary text-[13px] md:text-[14px] border-[1px] border-white hover:bg-primary hover:text-white font-semibold`,
                            imgClassName:
                                'w-full object-cover h-[600px] lg:h-full',
                            images: [
                                {
                                    imgUrl: 'https://godrejprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Godrej/Images/53911735.jpg',
                                    bgColor: '#7f7065',
                                    txtOne: 'REFERRALS AND EARNINGS',
                                    txtTwo: 'Building a community needs trust. As part of Godrej Ambassador, you can share your lifestyle with friends and family and earn benefits for successfully referring them. With us, you can build a community surrounded by people you love, creating a neighborhood that you feel connected to.',
                                    buttonText: 'REFER NOW',
                                    // MODIFY: REFER PAGE
                                    path: '/gift-and-earn'
                                    // scrollTo: 'referral_section'
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/13648823.jpg',
                                    bgColor: '#7f7065',
                                    txtOne: 'OFFERS AND PROMOTIONS',
                                    txtTwo: 'At Godrej Ambassador, we are all about creating value-added experiences that add more to your lifestyle. We give you access to premium and pan-india brands that elevate your lifestyle above the ordinary. Our goal is to curate the best experiences and bring them to you.',
                                    buttonText: 'AVAIL NOW',
                                    path: '/deals'
                                },
                                {
                                    imgUrl: 'https://godrejprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Godrej/Images/47378636.jpg',
                                    bgColor: '#7f7065',
                                    txtOne: 'WORKSHOPS AND EVENTS',
                                    txtTwo: 'Everyone needs a break from the monotony of life. Our workshops and events are designed to create engaging experiences with top collaborators, experts, and content creators.',
                                    buttonText: 'PARTICIPATE NOW',
                                    path: '/events'
                                },
                                {
                                    imgUrl: 'https://godrejprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Godrej/Images/69026160.jpg',
                                    bgColor: '#7f7065',
                                    txtOne: 'INTERACTIVE BLOGS',
                                    txtTwo: 'Reading adds more to what we know and how we perceive the world. As part of the Godrej Ambassador experience, we bring you bite-sized infotainment to keep adding to the things you know.',
                                    buttonText: 'READ NOW',
                                    path: '/blogs'
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    className:
                        'xl:w-full w-full xl:flex xl:h-auto bg-white items-center',

                    components: [
                        {
                            type: 'CUSTOM',
                            className:
                                ' w-full h-auto  flex flex-col justify-center text-center py-10 px-2 lg:px-8 xl:px-12',
                            components: [
                                {
                                    type: 'TEXT',
                                    content:
                                        'START BUILDING YOUR COMMUNITY NOW',
                                    className: `text-secondary leading-[19px] xl:leading-[20px] 2xl:leading-[22px] text-[17px] lg:text-[18px] xl:text-[20px] 2xl:text-[22px] ${
                                        getFont('GODREJ').heading
                                    }`
                                },
                                {
                                    type: 'TEXT',
                                    content:
                                        'The best communities are built on trust and care for one another. Godrej Ambassador offers you the opportunity to refer your loved ones and help them become a part of a community that goes beyond sharing a neighbourhood.',

                                    className: `text-secondary-focus xl:px-4 mx-auto leading-[21px] lg:leading-[21px] xl:leading-[22px] 2xl:leading-[24px] text-[16px] xl:text-[16.5px] 2xl:text-[17px] xl:w-[90%] 2xl:w-[75%] mt-4 ${
                                        getFont('GODREJ').body
                                    }`
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'REFERRAL_FORM_BANNER'
                },
                {
                    type: 'CUSTOM',
                    className: 'mt-14 mb-10',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'MAKE IT WORK FOR YOU',
                            className: `text-center  text-secondary tracking-wide text-[17px] lg:text-[18px] xl:text-[20px] 2xl:text-[22px] ${
                                getFont('GODREJ').heading
                            }`
                        },

                        {
                            type: 'ICONS_LAYOUT',
                            containerClassName:
                                'h-content lg:h-auto bg-white items-center pt-3 xl:pt-5 ',
                            gridClassName:
                                'flex lg:grid lg:grid-cols-2 w-full lg:w-[80%] m-auto overflow-x-scroll lg:overflow-x-hidden',

                            infoHeadingClassName: `w-full mx-auto leading-6 xl:leading-[27px] md:px-2 text-[20px] md:text-[22px] xl:text-xl  text-primary font-semibold pt-4 pb-2 tracking-wider ${
                                getFont('GODREJ').heading
                            }`,
                            infotxtClassName:
                                ' w-[90%] md:w-[50%] lg:w-[85%] xl:w-[70%] 2xl:w-[75%]  mx-auto l 2xl:px-10 text-[16px] xl:text-[16.5px] 2xl:text-[17px] font-Helvetica_Light  text-black font-normal leading-[21px] lg:leading-[21px] xl:leading-[22px] 2xl:leading-[24px] ',
                            infoContainerClassName:
                                'min-w-[80vw] lg:min-w-full mt-4 lg:mt-0',
                            imgContainerClassName: '',
                            imgClassName:
                                'w-[16%] md:w-[8%] lg:w-[13%] lg:mx-auto mx-auto lg:mb-2',
                            info: [
                                {
                                    imgUrl: 'https://cdn.loyalie.in/27025232.png',
                                    infoHeading: 'STEP 1',
                                    infoText:
                                        'We connect with your references and introduce them to Godrej Ambassador.'
                                },
                                {
                                    imgUrl: 'https://cdn.loyalie.in/86958134.png',
                                    infoHeading: 'STEP 2',
                                    infoText:
                                        'Once they book a home with Godrej Properties, you begin enjoying the rewards.'
                                }
                                // {
                                //     imgUrl: 'https://cdn.loyalie.in/639615.png',
                                //     infoHeading: 'STEP 3',
                                //     infoText:
                                //         "Get privileged access to content that's crafted to your interests and preferences, and interact with people of similar tastes."
                                // }
                            ]
                        }
                    ]
                },

                {
                    type: 'CUSTOM',
                    url: '',
                    id: 'about',
                    className:
                        'hide h-content xl:w-full w-full lg:flex xl:h-auto bg-white items-center border-t-[3px] border-b-[3px] border-[#7f7065]',
                    components: [
                        {
                            type: 'CUSTOM',
                            url: 'https://godrejprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Godrej/Images/43765574.png',
                            linearGradient: {
                                R: '255',
                                G: '255',
                                B: '255',
                                opacity: '0.7',
                                size: '500%',
                                position: 'center'
                            },

                            className:
                                ' w-[10%] lg:h-[500px] xl:h-[695px] 2xl:h-[748px]'
                        },

                        {
                            type: 'CUSTOM',
                            className:
                                '  w-full lg:w-[65%] text-[#7f7065] px-8 py-10  xl:px-10 xl:py-10   ',

                            components: [
                                {
                                    type: 'TEXT',
                                    content:
                                        'KNOW MORE ABOUT GODREJ PROPERTIES',
                                    className: `text-secondary tracking-wide text-[17px] lg:text-[18px] xl:text-[20px] 2xl:text-[22px] ${
                                        getFont('GODREJ').heading
                                    }`
                                },
                                {
                                    type: 'DIVIDER',
                                    className:
                                        'w-[25%] md:w-[16%] xl:w-[14%] bg-accent border-none h-[4px]',
                                    classNameContainer: 'mt-2'
                                },
                                {
                                    type: 'TEXT',
                                    content:
                                        "Godrej Properties brings the Godrej Group philosophy of innovation, sustainability, and excellence to the real estate industry. Each Godrej Properties development combines a 125–year legacy of excellence and trust with a commitment to cutting-edge design and technology. In recent years, Godrej Properties has received over 250 awards and recognitions, including ‘The Most Trusted Real Estate Brand’ in 2019 from the Brand Trust Report, 'Real Estate Company of the Year' at the 9th Construction Week Awards 2019, ‘Equality and Diversity Champion’ 2019 at the APREA Property Leaders Awards, ‘The Economic Times Best Real Estate Brand 2018’ and the ‘Builder of the Year’ at the CNBC-Awaaz Real Estate Awards 2018.  In 2016, we stood 2nd in Asia and 5th in the world in the GRESB (Global Real Estate Sustainability Benchmarking) study, which is an industry led sustainability and governance benchmarking platform.",

                                    className: `text-secondary-focus xl:pr-5 2xl:pr-8 3xl:pr-48 py-5 text-[15.5px] xl:text-[16.5px] 2xl:text-[17px] leading-[21px] lg:leading-[21px] xl:leading-[22px] 2xl:leading-[24px] ${
                                        getFont('GODREJ').body
                                    }`
                                }
                                // {
                                //     type: 'TEXT',
                                //     content:
                                //         "Our projects over the years have delivered many firsts in the Indian real estate market. Planet Godrej, a skyscraper in Mumbai, was India's tallest occupied building when completed in 2008. It also illustrated our focus on customer safety and wellbeing by becoming the first project in the country to offer residents a fire escape chute. Our commercial office project, Godrej BKC, is the only LEED (Leadership in Energy and Environmental Design) Platinum rated building in India's leading commercial district, Bandra Kurla Complex, demonstrating Godrej Properties' commitment to environmental sustainability. It is also the project where we broke the record for India's highest ever commercial end-user sales transaction when a large multinational pharmaceutical company purchased space in this project for INR 1,479 crore in 2015. Our flagship project, The Trees, is one of India's most sustainably planned mixed-use projects that we hope will contribute to the evolution of urban design thinking in the country. We sold over INR 1,200 crore worth of space within six months of launching this project in 2015, making it one of the country's most successful residential project launches.",

                                //     className: `text-secondary-focus xl:pr-5 2xl:pr-8  3xl:pr-48 pb-5 pt-4 text-[15.5px] xl:text-[16.5px] 2xl:text-[17px] leading-[19px] xl:leading-[20px] 2xl:leading-[22px] ${
                                //         getFont('GODREJ').body
                                //     }`
                                // },
                                // {
                                //     type: 'TEXT',
                                //     content:
                                //         "With an estimated 10 million Indians moving into the urban areas annually, the country's urban landscape is likely to change dramatically in the coming decades. We firmly believe that India must seize on the opportunity to urbanize in a sustainable manner. Our group has always been at the forefront of the environmental sustainability movement. The CII-Godrej Green Building Center in Hyderabad, when it was completed in 2004, was the first LEED Platinum building outside of the United States and was the single highest rated LEED building in the world.  In 2010, Godrej Properties committed that every single project we develop will be a certified green building. Many of our projects have since received LEED Platinum certifications, which are globally recognized as the leading sustainability recognitions. Our large township project, Godrej Garden City, in Ahmedabad was selected as one of only 2 projects in India and 16 worldwide by The Clinton Foundation to partner with them in the goal of achieving a climate positive development. In 2016, we stood 2nd in Asia and 5th in the world in the GRESB (Global Real Estate Sustainability Benchmarking) study, which is an industry led sustainability and governance benchmarking platform. ",

                                //     className: `text-secondary-focus xl:pr-5 2xl:pr-8  3xl:pr-48 pb-5 pt-4 text-[15.5px] xl:text-[16.5px] 2xl:text-[17px] leading-[19px] xl:leading-[20px] 2xl:leading-[22px] ${
                                //         getFont('GODREJ').body
                                //     }`
                                // }
                            ]
                        },
                        {
                            type: 'IMAGE',
                            url: 'https://godrejprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Godrej/Images/10983397.jpg',
                            position: 'center',
                            classNameContainer:
                                'lg:w-[35%] w-[100%] flex lg:gap-0 gap-0'
                        }
                    ]
                },

                {
                    type: 'CUSTOM',
                    className:
                        'text-center w-[100%] text-[#7f7065]  xl:py-10 py-10',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'GODREJ PROPERTIES LATEST BLOGS',
                            className: `text-secondary tracking-wide px-6 leading-[20px] xl:leading-[20px] 2xl:leading-[22px] lg:px-0 text-[17px] lg:text-[18px] xl:text-[20px] 2xl:text-[22px] ${
                                getFont('GODREJ').heading
                            }`
                        },
                        {
                            type: 'DIVIDER',
                            className:
                                'self-center m-auto w-[20%] xl:w-[10%] bg-accent  border-none h-[3px] xl:h-[4px]',
                            classNameContainer: 'mt-3'
                        }
                    ]
                },
                {
                    type: 'BLOG_LAYOUT_VERTICAL1',
                    // url: 'https://godrejprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Godrej/Images/42577492.png',
                    // linearGradient: {
                    //     R: '255',
                    //     G: '255',
                    //     B: '255',
                    //     opacity: '0.95',
                    //     size: '60%'
                    // },
                    className:
                        'w-full flex flex-col-reverse lg:flex-row h-auto relative mx-auto justify-center'
                    // // API: 'https://godrejambassador.reloy.co/wp-json/wp/v2/posts',
                    // headingClass: `text-[18px] xl:text-[20px] 2xl:text-[22px] leading-[19px] xl:leading-[21px] 2xl:leading-[22px]  uppercase tracking-wide text-primary px-4 md:px-12 py-4 md:py-7 lg:px-10 lg:py-4 ${
                    //     getFont('GODREJ').heading
                    // }`,
                    // bodyClass: `text-secondary-focus px-4 md:px-12 lg:px-10 text-[16px]  xl:text-[16.5px] 2xl:text-[17px] leading-[18px]  xl:leading-[19px] 2xl:leading-[22px] ${
                    //     getFont('GODREJ').body
                    // }`,
                    // buttonClass: ` text-[16px] 2xl:text-[17px] px-4 md:px-12 py-4 md:py-7 lg:px-10 lg:pt-3 text-primary ${
                    //     getFont('GODREJ').heading
                    // }`
                },
                {
                    type: 'CUSTOM',
                    className:
                        'xl:text-center text-center xl:w-full w-[100%] text-white  xl:pb-5  pb-10',
                    components: [
                        {
                            type: 'BUTTON',
                            content: 'LOAD MORE',
                            fontFamily: 'Helvetica_Medium',
                            path: 'blogs',
                            onClick: 'CHANGE_PATH'
                        }
                    ]
                },
                {
                    type: 'CUSTOM',
                    className:
                        'hide xl:text-center text-center xl:w-full w-[100%] text-[#7f7065]  xl:py-10  py-10',

                    id: 'godrej_projects_locations',
                    components: [
                        {
                            type: 'TEXT',
                            content: 'OUR PROJECT LOCATIONS',
                            className: `text-secondary tracking-wide text-[18px] xl:text-[20px] 2xl:text-[22px] ${
                                getFont('GODREJ').heading
                            }`
                        },
                        {
                            type: 'DIVIDER',
                            className:
                                'self-center m-auto  w-[25%] md:w-[16%] lg:w-[10%]  bg-accent  border-none h-[4px]',
                            classNameContainer: 'mt-2'
                        },
                        {
                            type: 'IMAGE_SECTION',
                            className:
                                'flex overflow-x-scroll lg:overflow-x-hidden lg:grid md:grid-flow-col hover:text-primary md:auto-cols-fr w-[90%] md:w-[60%] lg:w-[90%] xl:w-[80%] m-auto h-content text-black',
                            photosection: 'mt-7 min-w-[50vw] lg:min-w-full',
                            imgClassName:
                                'px-0  lg:px-4 text-center m-auto w-[50%] lg:w-[70%]',

                            imgArr: [
                                {
                                    imgSrc: 'https://godrejprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Godrej/Images/26171152.jpg',
                                    text: 'Mumbai',

                                    link: 'https://www.godrejproperties.com/bycity/index/mumbai'
                                },
                                {
                                    imgSrc: 'https://godrejprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Godrej/Images/16685822.jpg',
                                    text: 'Pune',

                                    link: 'https://www.godrejproperties.com/bycity/index/pune'
                                },
                                {
                                    imgSrc: 'https://godrejprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Godrej/Images/7024385.jpg',
                                    text: 'Bangalore',

                                    link: 'https://www.godrejproperties.com/bycity/index/bangalore'
                                },
                                {
                                    imgSrc: 'https://godrejprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Godrej/Images/17850835.jpg',
                                    text: 'Delhi NCR',

                                    link: 'https://www.godrejproperties.com/bycity/index/ncr'
                                },
                                {
                                    imgSrc: 'https://godrejprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Godrej/Images/83359253.jpg',
                                    text: 'Kolkata',
                                    link: 'https://www.godrejproperties.com/bycity/index/kolkata'
                                },
                                {
                                    imgSrc: 'https://godrejprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Godrej/Images/22896570.jpg',
                                    text: 'Ahmedabad',
                                    link: 'https://www.godrejproperties.com/bycity/index/ahmedabad'
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        referPage: {
            show: false,
            name: 'REFER NOW',
            path: '/gift-and-earn',
            component: 'ReferAndEarn'
        },
        referSection: {
            show: true,
            name: 'REFER NOW',
            scrollTo: 'referral_section',
            path: '/',
            component: 'ReferAndEarn'
        },
        about: {
            name: 'ABOUT GODREJ PROPERTIES',
            scrollTo: 'about',
            path: '/',
            component: 'GodrejHome',
            show: true
        },
        dropDown: {
            show: false,
            name: 'COMMUNITY ENGAGEMENT',
            path: '/',
            component: '',
            dropDown: true,
            dropDownOptions: [
                {
                    blogs: {
                        show: true,
                        name: 'BLOGS',
                        path: '/blogs'
                    }
                },
                {
                    events: {
                        show: true,
                        name: 'EVENTS',
                        path: '/events'
                    }
                }
                // {
                //     deals: {
                //         show: true,
                //         name: 'BENEFITS',
                //         path: '/deals'
                //     }
                // }
            ]
        },
        events: {
            name: 'CURATED EVENTS',
            path: '/events',
            component: 'Events',
            show: true
        },
        blogs: {
            name: 'HOME & LIFESTYLE TRENDS',
            // customUrl: 'https://godrejambassador.reloy.co',
            path: '/blogs',
            component: 'Blogs',
            show: true
        },
        deals: {
            name: 'MEMBERSHIP BENEFITS',
            path: '/deals',
            component: 'DealsAndDiscounts',
            show: true
        },
        projects: {
            name: 'PROJECTS',
            path: '/projects',
            component: 'Projects',
            show: false
        },
        contactUs: {
            name: 'CONTACT US',
            path: '/contact-us',
            component: 'Contact-Us',
            show: false
        },
        termsAndConditions: {
            name: 'TERMS AND CONDITIONS',
            path: '/terms-and-conditions',
            component: 'TermsAndConditions',
            show: false
        }
    },
    referPage: {
        banner: {
            loginBannerComponents: [
                {
                    type: 'HORIZONTAL_CAROUSEL',
                    duration: 2500,
                    arrows: false,
                    imageFit: 'object-contain',
                    imgHeight:
                        'h-[70vh] md:h-[100vh] lg:h-[70vh] xl:h-[90vh] 2xl:h-[90vh]',
                    images: [
                        {
                            imgUrl: 'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1721729841597_refer banner 1 lg',
                            imgPosition: 'object-center',
                            mobileImgUrl:
                                'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1721729854679_refer banner 1 sm',
                            mobileImgPosition: 'object-center',
                            onClick: 'FUNC',
                            onClickFunc: () =>
                                store.dispatch({
                                    type: 'authentication/openLoginModal'
                                }),
                            
                        },
                        // {
                        //     imgUrl: 'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1721729871747_refer banner 2 lg',
                        //     imgPosition: 'object-center',
                        //     mobileImgUrl:
                        //         'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1721729883634_refer banner 2 sm',
                        //     mobileImgPosition: 'object-center',
                        //     onClick: 'FUNC',
                        //     onClickFunc: () =>
                        //         store.dispatch({
                        //             type: 'authentication/openLoginModal'
                        //         }),
                            
                        // } 
                    ]
                },
                // {
                //     type: 'BANNER',
                //     linearGradient: {
                //         R: '0',
                //         G: '0',
                //         B: '0',
                //         opacity: '0'
                //     },
                //     backgroundImage:
                //         'https://cdn.loyalie.in/1692637968652_refer%20page',
                //     backgroundImageMobile:
                //         'https://cdn.loyalie.in/1692637968652_refer%20page',
                //     bannerContainerClassName:
                //         'relative hero hero-content max-w-full h-[70vh] object-cover bg-center lg:bg-left',
                //     components: [
                //         {
                //             type: 'TEXT',
                //             content:
                //                 'Refer your loved ones and get rewards on every successful referral',
                //             className: `text-black text-[19px] md:text-[20px] xl:text-[25px] text-center lg:text-left uppercase  tracking-wide  lg:w-[50%] ml-auto lg:leading-8 ${
                //                 getFont('GODREJ').secondary
                //             }`
                //         },
                //         {
                //             type: 'CUSTOM',
                //             className: 'flex lg:w-[50%] ml-auto',
                //             components: [
                //                 {
                //                     type: 'BUTTON',
                //                     content: 'Start Referring Now',
                //                     onClickFunc: () =>
                //                         store.dispatch({
                //                             type: 'authentication/openLoginModal'
                //                         }),
                //                     onClick: 'FUNC',
                //                     className: `godrej-button hover:godrej-button-hover text-sm mt-3 mx-auto lg:mx-0 ${
                //                         getFont('GODREJ').heading
                //                     }`
                //                 }
                //             ]
                //         }
                //     ]
                // }
            ]
        },

        components: [
            {
                type: 'HORIZONTAL_CAROUSEL',
                duration: 2500,
                arrows: false,
                imageFit: 'object-contain',
                    imgHeight:
                        'h-[70vh] md:h-[100vh] lg:h-[70vh] xl:h-[90vh] 2xl:h-[90vh]',
                images: [
                    {
                        imgUrl: 'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1721729841597_refer banner 1 lg',
                        imgPosition: 'object-center',
                        mobileImgUrl:
                            'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1721729854679_refer banner 1 sm',
                        mobileImgPosition: 'object-center',
                        onClick: 'SCROLL',
                        scrollTo: 'referral_section'
                        
                    },
                    {
                        imgUrl: 'https://cdn.loyalie.in/1692637968652_refer%20page',
                        imgPosition: 'object-center',
                        mobileImgUrl:
                            'https://cdn.loyalie.in/1692637968652_refer%20page',
                        mobileImgPosition: 'object-center',
                        onClick: 'SCROLL',
                        scrollTo: 'referral_section'
                        
                    } 
                ]
            },
            
            // {
            //     type: 'BANNER',
            //     linearGradient: {
            //         R: '0',
            //         G: '0',
            //         B: '0',
            //         opacity: '0'
            //     },
            //     position: 'text-left',
            //     backgroundImage:
            //         'https://cdn.loyalie.in/1692637968652_refer%20page',
            //     backgroundImageMobile:
            //         'https://cdn.loyalie.in/1692637968652_refer%20page',
            //     bannerContainerClassName:
            //         'relative hero hero-content max-w-full h-[80vh] md:h-[70vh] object-cover bg-left'
            // }
        ],

        customize: {
            claimButton: `text-sm md:text-md lg:font-bold bg-primary text-white ${
                getFont('GODREJ').heading
            }`,
            deactivateProjectClick: true
        }
    },
    blogs: {
        banner: {
            bannerContainerClassName:
                'hidden md:hero hero-content max-w-full h-[60vh] md:h-[70vh] lg:h-[97vh] md:bg-top mx-auto text-center ',
            linearGradient: {
                string: '100,65,43,0.7',
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.20'
            },
            backgroundImage:
                'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1701334178809_bbanner',
            backgroundImageMobile:
                'https://loyalie-cms-dev.s3.ap-south-1.amazonaws.com/1701334178809_bbanner',

            components: [
                {
                    type: 'TEXT',
                    content: 'GODREJ AMBASSADOR READERS’ CLUB:',
                    className: ` text-[28px] md:text-[40px] lg:text-[30px] xl:text-[35px]  tracking-wider ${
                        getFont('GODREJ').heading
                    }`
                },
                // {
                //     type: 'DIVIDER',
                //     className:
                //         'mt-1 self-center mx-auto w-[40%] lg:w-[15%] bg-primary border-none h-[4px]',
                //     classNameContainer: ''
                // },
                {
                    type: 'TEXT',
                    content: 'Discover the latest home & lifestyle trends',
                    className: `font-medium text-[20px] 2xl:text-[22px] leading-6 mb-3 ${
                        getFont('GODREJ').heading
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Read Now',
                    scrollTo: 'blogs_section',
                    onClick: 'SCROLL'
                }
            ],
            loginBannerComponents: [
                {
                    type: 'TEXT',
                    content: 'GODREJ AMBASSADOR READERS’ CLUB:',
                    className: ` text-[28px] md:text-[40px] lg:text-[30px] xl:text-[35px]   tracking-wider ${
                        getFont('GODREJ').heading
                    }`
                },
                // {
                //     type: 'DIVIDER',
                //     className:
                //         'mt-1 self-center mx-auto w-[40%] lg:w-[15%] bg-primary border-none h-[4px]',
                //     classNameContainer: ''
                // },
                {
                    type: 'TEXT',
                    content: 'Discover the latest home & lifestyle trends',
                    className: ` font-medium text-[20px] 2xl:text-[22px] leading-6 mb-3 ${
                        getFont('GODREJ').heading
                    }`
                },
                {
                    type: 'BUTTON',
                    content: 'Login To Read Now',
                    onClick: 'FUNC',
                    onClickFunc: () =>
                        store.dispatch({
                            type: 'authentication/openLoginModal'
                        })
                }
            ]
        },
        sideBarClass: `text-primary ${getFont('GODREJ').heading}`,
        sideBarBorder: 'border-none',
        sideBarHeadingClass: `text-[16px] text-[#373737] capitalize leading-5 font-semibold`,
        sideBarDateClass: `text-[#64412b] text-[12px] ${
            getFont('GODREJ').heading
        }`,
        comments: {
            commentSubmittedBg: 'bg-primary',
            commentsBg: ' ',
            commentButtonClass: 'bg-primary text-white',
            initialsClass: ' ',
            replyBorder: ' '
        },
        sliderBanner: {
            featuredPost: {
                featuredPostClass: `uppercase mt-3 lg:mt-0  font-semibold text-[20px] text-[#1c2936] ${
                    getFont('GODREJ').heading
                }`,
                headingClass: `text-[30px] uppercase font-semibold text-primary mt-3 leading-9 break-words ${
                    getFont('GODREJ').heading
                }`,
                summaryClass: `inline text-[17px] mt-8 text-[#1c2936]  min-h-[40px] lg:min-h-[70px] ${
                    getFont('GODREJ').heading
                }`,
                dateClass: `text-[13px] uppercase text-[#1c2936] font-semibold my-3 ${
                    getFont('GODREJ').heading
                } `,
                buttonClass: `inline text-[17px] text-primary ml-1 ${
                    getFont('GODREJ').heading
                }`
            },
            sectionHeadings: `px-10 lg:px-16 pt-10 pb-2 text-[22px] lg:text-[25px] xl:text-[35px] tracking-wider text-center uppercase ${
                getFont('GODREJ').heading
            }`,
            dividerClass: 'hidden m-0 p-0 '
        },
        layout5: {
            dividerClass: 'hidden m-0 p-0 ',

            backgroundColor: 'bg-accent',
            cardHeight: ' h-[530px] lg:h-[520px] xl:h-[540px] 3xl:h-[580px] ',
            bodyHeight:
                ' h-[280px] md:h-[280px] lg:h-[270px] xl:h-[290px] 3xl:h-[380px]',
            headingClass: `text-primary my-5 md:my-7 text-[21px] 3xl:text-[20px] font-medium uppercase leading-6 font-semibold break-words mx-5 ${
                getFont('GODREJ').heading
            }`,

            summaryClass: `font-normal text-[16.5px] lg:text-[15px] xl:text-[17px] mt-2 lg:my-6 leading-6 text-secondary mx-5 ${
                getFont('GODREJ').body
            }`,
            categoryClass: 'bg-[#00000075] text-white text-[11px]',
            buttonClass: `bg-primary flex justify-center self-start text-[13px] xl:text-[14px] text-white rounded-md py-2 px-4 md:px-4 m-5 ${
                getFont('GODREJ').body
            }`,
            dateClass: `text-[14px] md:text-[17px] xl:text-[15px]  self-center text-[#919396] ${
                getFont('GODREJ').heading
            }`,
            noBlogsClass: `bg-primary text-white p-2 md:w-[30%] lg:w-[70%] xl:w-[50%] 3xl:w-[30%] mt-10 ${
                getFont('GODREJ').body
            }`
        }

        // {
        //     type: 'IFRAME',
        //     id: 'Iframe',
        //     url: 'https://godrejambassador.reloy.co',
        //     height: '100%',
        //     width: '100%',
        //     className:
        //         'h-[100vh] scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-400 '
        // }
    },

    events: {
        banner: {
            defaultActiveBannerImage: 'https://cdn.loyalie.in/77871304.jpg',
            mobileDefaultActiveBannerImage:
                'https://cdn.loyalie.in/77871304.jpg',
            linearGradient: {
                R: '0',
                G: '0',
                B: '0',
                opacity: '0.2'
            },
            containerClass: 'hero self-center my-auto h-full text-white',
            heading: 'Events Archive',
            headingClass: `text-white mt-10 mb-5 text-2xl md:text-[35px] uppercase tracking-widest ${
                getFont('GODREJ').heading
            }`,
            subHeadingClass: `text-white uppercase font-semibold text-[28px] md:text-[33px] ${
                getFont('GODREJ').heading
            }`,

            negativeMargin: '-mt-16 2xl:-mt-16'
        },
        loginBanner: {
            bannerContainerClassName:
                'mt-16 2xl:mt-16 hero hero-content lg:bg-right bg-cover max-w-full h-[55vh] md:h-[70vh] lg:h-[90vh] mx-auto text-center ',
            components: [
                // {
                //     type: 'TEXT',
                //     content: 'CURATED WORKSHOPS AND EVENTS',
                //     className: ` mb-3 text-secondary text-[28px] md:text-[40px] lg:text-[30px] xl:text-[35px]  text-secondary tracking-wider ${
                //         getFont('GODREJ').heading
                //     }`
                // },
                // {
                //     type: 'DIVIDER',
                //     className:
                //         'mt-1 self-center mx-auto w-[40%] lg:w-[15%] bg-primary border-none h-[4px]',
                //     classNameContainer: ''
                // },
                // {
                //     type: 'TEXT',
                //     content:
                //         'Meet and connect with like-minded people of your bespoke community',
                //     className: `mt-5 text-secondary font-medium text-[20px] 2xl:text-[22px] leading-[22px] ${
                //         getFont('GODREJ').heading
                //     }`
                // },
                // {
                //     type: 'BUTTON',
                //     content: 'Login To Participate Now',
                //     onClickFunc: () =>
                //         store.dispatch({
                //             type: 'authentication/openLoginModal'
                //         }),
                //     onClick: 'FUNC',
                //     className: ` lg:mx-0 mx-auto text-center lg:text-left mt-6  text-[15px] md:text-[17px] 2xl:text-[22px] tracking-wider bg-primary text-white rounded-[5px] py-3 md:py-2 2xl:py-[11px] px-5 md:px-6 ${
                //         getFont('GODREJ').body
                //     }`
                // }
            ]
        },
        eventsSection: {
            sectionBackgroundImage: godrejPattern,
            linearGradient: {
                R: '255',
                G: '255',
                B: '255',
                opacity: '0.98' //no gradient
            },
            heading: 'PAST EVENTS',
            headingClass: `self-center text-[30px] text-center text-2xl mt-14 text-primary w-[60%] ${
                getFont('GODREJ').heading
            }`,
            dividerClass:
                'w-[30%] md:w-[16%] lg:w-[7%] bg-[#373737] my-2 mx-auto border-none h-[4px]',
            filterHeading: 'BROWSE EVENT CATEGORIES',
            filterHeadingClass: `self-center text-[22px] text-center text-xl mt-8 text-primary w-[60%] ${
                getFont('GODREJ').heading
            }`,
            additionalText: ' ',
            additionalTextClass: ` `,
            subAdditionalText: ' ',
            subAdditionalTextClass: `pb-16`,
            noEvents: '',
            noEventsClass: `mx-auto px-6 lg:px-10 rounded-sm py-2 text-white text-[17px] md:text-xl 3xl:text-2xl bg-accent text-center ${
                getFont('GODREJ').body
            }`
        },
        eventLayout: {
            titleClass: `capitalize text-secondary ${
                getFont('GODREJ').heading
            }`,
            hostClass: '',
            dateClass: '',
            loadMoreButtonClass: `godrej-button hover:godrej-button-hover ${
                getFont('GODREJ').heading
            }`
        }
    },
    eventsGallery: {
        show: true,
        noGalleryClass: `text-primary font-semibold ${getFont('GODREJ').body}`
    },

    deals: {
        banner: {
            bannerContainerClassName:
                'hidden md:hero hero-content  max-w-full h-[30vh] lg:h-[45vh]',
            linearGradient: {
                string: '100,65,43,0.7',
                R: '20',
                G: '20',
                B: '20',
                opacity: '0'
            },
            backgroundImage:
                'https://cdn.loyalie.in/1692635625636_offer%20godrej',
            backgroundImageMobile:
                'https://cdn.loyalie.in/1692635625636_offer%20godrej',
            components: [
                // {
                //     type: 'TEXT',
                //     content: 'EXCLUSIVE MEMBERSHIP BENEFITS FOR YOU',
                //     className: `text-center font-semibold text-white mt-10 mb-5 text-2xl md:text-[35px] tracking-widest ${
                //         getFont('GODREJ').medium
                //     }`
                // }
            ],
            loginBannerComponents: [
                // {
                //     type: 'TEXT',
                //     content: 'EXCLUSIVE MEMBERSHIP BENEFITS FOR YOU',
                //     className: `text-center font-semibold text-white mt-10 mb-5 text-2xl md:text-[35px] tracking-widest ${
                //         getFont('GODREJ').medium
                //     }`
                // },
                // {
                //     type: 'BUTTON',
                //     content: 'Login To Avail Now',
                //     className: `flex mx-auto text-sm godrej-button hover:godrej-button-hover lg:mt-2 xl:mt-0 ${
                //         getFont('GODREJ').heading
                //     }`,
                //     onClickFunc: () =>
                //         store.dispatch({
                //             type: 'authentication/openLoginModal'
                //         }),
                //     onClick: 'FUNC'
                // }
            ]
        },
        dealsSection: {
            defaultBackgroundImage: 'https://cdn.loyalie.in/21334129.jpg',
            filterHeading: 'BROWSE SHOP CATEGORIES',
            filterHeadingClass: `z-10 self-center text-[22px]  text-center lg:text-xl xl:text-2xl text-primary w-[60%] ${
                getFont('GODREJ').heading
            }`,

            additionalText: 'Curating exclusive offers and promotions for you',
            additionalTextClass: `z-10 text-2xl xl:text-[22px] text-primary uppercase  ${
                getFont('GODREJ').heading
            } `,
            subAdditionalText:
                'Godrej properties is driven to create luxury experiences that add more value to your life. We urge you to avail our offers and promotions listed below.',
            subAdditionalTextClass: `z-10 capitalize text-secondary-focus ${
                getFont('GODREJ').body
            }`,
            noDeals: 'No deals to show',
            noDealsClass: `z-10 text-secondary-focus text-xl text-center my-6
             ${getFont('GODREJ').body}`
        },
        dealLayout: {
            containerClassNameBg1:
                'bg-white text-accent-content px-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',
            containerClassNameBg2:
                'bg-white text-accent-content px-6 py-3 md:py-10 lg:py-0 h-full lg:flex ',
            listTitleClass: `text-2xl lg:text-xl leading-[20px] 2xl:leading-[22px] text-primary ${
                getFont('GODREJ').heading
            }`,
            listBodyClass: `${getFont('GODREJ').body}`,
            logoClassName:
                'self-center rounded-md border-[1px] border-grey-100',
            titleClassName: `text-primary  uppercase text-2xl xl:text-3xl mt-3 ${
                getFont('GODREJ').heading
            } `,
            couponClassName: `block  text-primary text-sm md:text-base xl:text-xl  ${
                getFont('GODREJ').heading
            }`,
            dateClassName: `text-[#292929] font-extrabold capitalize text-[12px]  ${
                getFont('GODREJ').body
            }`,
            termsAndConditionsClassName: `text-[#292929] font-extrabold capitalize text-[12px] self-center ${
                getFont('GODREJ').body
            }`,
            termsArrowClassName: 'self-center text-2xl text-black',
            termsClassName: ` text-[#292929] text-xs font-bold break-words leading-4 ${
                getFont('GODREJ').body
            }`,

            grabOfferButtonConfig: {
                type: 'BUTTON',
                content: 'Grab Your Offer',

                className: `godrej-button hover:godrej-button-hover rounded-3xl px-9 py-3 text-[14px] self-center  uppercase  ${
                    getFont('GODREJ').heading
                } `
            },
            redeemNowButtonConfig: {
                type: 'BUTTON',
                content: 'Redeem Now',

                className: `godrej-button hover:godrej-button-hover rounded-3xl px-6 xl:px-9 py-3 text-[14px] self-center  uppercase  ${
                    getFont('GODREJ').heading
                } `
            }
        }
    },
    projects: {
        // components: [
        //     {
        //         type: 'BANNER',
        //         backgroundImage: '',
        //         linearGradient: {
        //             R: '255',
        //             G: '255',
        //             B: '255',
        //             opacity: '0'
        //         },
        //         components: [
        //             {
        //                 type: 'TEXT',
        //                 content: 'GODREJ PROJECTS',
        //                 className: `mt-10 font-semibold text-2xl md:text-4xl text-white tracking-widest ${
        //                     getFont('GODREJ').heading
        //                 }`
        //             }
        //         ]
        //     }
        // ]
        moduleConfig: {
            hideProjects: false
        }
    },
    // header: {
    //     headerClass: 'hidden lg:block h-24 bg-black'
    // },
    contactUs: {
        components: [
            {
                type: 'BANNER',
                backgroundImage:
                    'https://godrejprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Godrej/Images/81982436.jpg',

                backgroundImageMobile:
                    'https://godrejprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Godrej/Images/81982436.jpg',
                bannerContainerClassName:
                    'hidden md:hero hero-content max-w-full h-[97vh]  ', //h-[400px] lg:h-[480px] 2xl:h-[580px]
                linearGradient: {
                    R: '22',
                    G: '22',
                    B: '22',
                    opacity: '0'
                },
                components: [
                    {
                        type: 'TEXT',
                        content: 'CONTACT US',
                        className: `text-center mt-10 font-semibold text-2xl md:text-4xl text-white tracking-widest ${
                            getFont('GODREJ').heading
                        }`
                    }
                ]
            }
        ]
    },
    termsAndConditions: {
        listStyle: 'list-disc',
        titleClass: `mt-4 tracking-wide text-black text-[17px] md:text-[17px] ${
            getFont('GODREJ').heading
        }`,
        listClassName: `text-black text-[16px] xl:text-[16.5px] 2xl:text-[17px] leading-[19px] xl:leading-[20px] 2xl:leading-[22px] ${
            getFont('GODREJ').body
        }`,
        components: [
            {
                type: 'BANNER',
                backgroundImage:
                    'https://godrejprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Godrej/Images/26501546.jpg',

                backgroundImageMobile:
                    'https://godrejprod.s3.ap-south-1.amazonaws.com/Website/Uploads/Godrej/Images/26501546.jpg',
                bannerContainerClassName:
                    'hidden md:hero hero-content max-w-full h-[87vh] md:bg-right lg:bg-left', //h-[400px] lg:h-[480px] 2xl:h-[580px]

                linearGradient: {
                    R: '22',
                    G: '22',
                    B: '22',
                    opacity: '0'
                },
                components: [
                    {
                        type: 'TEXT',
                        content: 'TERMS AND CONDITIONS',
                        className: `text-center  text-white text-2xl md:text-[35px] font-semibold tracking-widest ${
                            getFont('GODREJ').heading
                        }`
                    }
                ]
            },
            {
                type: 'CUSTOM',
                className: 'text-left w-full p-2 py-4 md:p-10',

                components: [
                    {
                        type: 'TEXT',
                        content: 'ANNEXURE A',
                        className: `tracking-wide text-black px-16 font-normal mt-5 mb-5 text-[20px] md:text-[20px] ${
                            getFont('GODREJ').heading
                        }`
                    },
                    {
                        type: 'TEXT',
                        content:
                            'The Customer Agrees To Be Bound By The Terms And Conditions Of The Referral Reward Program As Mentioned Below:',
                        className: `tracking-wide text-black px-16 text-[17px] md:text-[17px] ${
                            getFont('GODREJ').heading
                        }`
                    },
                    {
                        type: 'LIST',
                        listStyle: 'list-disc',

                        points: [
                            '“Referrer” shall mean either of (i) an existing eligible customer having booked a unit in a Godrej Properties project, and has entered into a registered Agreement to Sell; or (ii) employee of Godrej Properties; or (iii) a visit done not booked person/client (VDNB); or (iv) influencers; and wishes to introduce/refer another person(s) to book a unit in any of the eligible registered real estate project/s to be developed by Godrej Properties or any of its sister concerns/subsidiaries/affiliates (“Godrej Properties Project/s”). ‘Referred’ shall mean the eligible person introduced/referred to by the Referrer and who intends to book one or more units in any of the eligible registered real estate Godrej Properties Project/s and registers an agreement for sale for such unit(s).',
                            'Referrer participation in the Referral Reward Program shall be subject to his/her/them producing a copy of a valid and subsisting registered Agreement to Sell executed in his/her/their favor by Godrej Properties.',
                            'Referrer shall not be able to refer existing customer(s) of Godrej Properties. Referrer shall not be able to refer customer(s) who has/have in the last three months: (i) either made enquires (in any form, whether by way of site visit or otherwise with Godrej Properties or (ii) is/are already registered with Godrej Properties as a ‘Direct/Walk-in Customer’ or (iii) has/have come through any of the Godrej Properties’s Channel Partner or any other source. Assigning Referral Reward and determining eligibility as to who qualifies as ‘Referrer’ and ‘Referred’ will be at sole discretion of Reloy and the same shall not be challenged/contested by the Referrer and/or the Referred at any point of time.',
                            'In the event that a referral is submitted twice from different Referrers, then the first referral received will be eligible for the Reward (subject to other applicable terms herein) and shall not be disputed by the other Referrers.',
                            'Referrals are not valid where the Referrer and the Referred party are one and the same person.',
                            'Referrals will be valid for three months from the date a person/party is ‘Referred’ unless such person/party has paid any booking amount to Godrej Properties within the said period of three months.',
                            'By accepting our terms and conditions, both Referrer and Referred represent that they have the authority to disclose the name and details of the person or business to Godrej Properties.',
                            'Subject to the terms mentioned herein, the reward will be paid to the Referrer within 90 (ninety) days from the date of registration of the agreement for sale by the Referred party. The reward will be in the form of gift vouchers/cards having value and a validity period with applicable terms and conditions.',
                            'This Referral Reward Program cannot be clubbed with any other offer /scheme, whether run by Godrej Properties or otherwise.',
                            'Customers who are employees of working either in marketing, sales or CRM departments/team and employees of Reloy will not be eligible for participating in this Referral Reward Program and accordingly shall not be entitled for any reward.',
                            'Reloy will not be liable for any unforeseen delay/deficiency/defect in any product or services disbursed as rewards by it or on account of any dispute relating to right to utilize the product or services subsequent to disbursement of the same.',
                            'The terms and conditions of this program are subject to change at any time. Reloy will intimate the same to the Referrer. This program may be terminated at the discretion of Reloy.',
                            'All disputes shall be subject to exclusive jurisdiction of Mumbai courts.'
                        ],
                        listClassName: `text-black text-[16px] xl:text-[16.5px] 2xl:text-[17px] leading-[19px] xl:leading-[20px] 2xl:leading-[22px] ${
                            getFont('GODREJ').body
                        }`,
                        classNameContainer: 'px-16 mt-6'
                    },
                    {
                        type: 'RAZORPAY_TERMS',
                        classNameContainer: 'px-16 mt-6'
                    },

                    {
                        type: 'CUSTOM',
                        className: 'text-left w-full p-2 py-4 ',
                        components: [
                            {
                                type: 'TEXT',
                                content: 'ANNEXURE A',
                                className: `tracking-wide text-black px-16 font-normal mt-5 mb-5 text-[20px] md:text-[20px] ${
                                    getFont('GODREJ').heading
                                }`
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'The Customer Agrees To Be Bound By The Terms And Conditions Of The Referral Reward Program As Mentioned Below:',
                                className: `tracking-wide text-black px-16 text-[17px] md:text-[17px] ${
                                    getFont('GODREJ').heading
                                }`
                            },
                            {
                                type: 'LIST',
                                listStyle: 'list-disc',
                                points: [
                                    '“Referrer” shall mean either of (i) an existing eligible customer having booked a unit in a Godrej Properties project, and has entered into a registered Agreement to Sell; or (ii) employee of Godrej Properties; or (iii) a visit done not booked person/client (VDNB); or (iv) influencers; and wishes to introduce/refer another person(s) to book a unit in any of the eligible registered real estate project/s to be developed by Godrej Properties or any of its sister concerns/subsidiaries/affiliates (“Godrej Properties Project/s”). ‘Referred’ shall mean the eligible person introduced/referred to by the Referrer and who intends to book one or more units in any of the eligible registered real estate Godrej Properties Project/s and registers an agreement for sale for such unit(s).',
                                    'Referrer participation in the Referral Reward Program shall be subject to his/her/them producing a copy of a valid and subsisting registered Agreement to Sell executed in his/her/their favor by Godrej Properties.',
                                    'Referrer shall not be able to refer existing customer(s) of Godrej Properties. Referrer shall not be able to refer customer(s) who has/have in the last three months: (i) either made enquires (in any form, whether by way of site visit or otherwise with Godrej Properties or (ii) is/are already registered with Godrej Properties as a ‘Direct/Walk-in Customer’ or (iii) has/have come through any of the Godrej Properties’s Channel Partner or any other source. Assigning Referral Reward and determining eligibility as to who qualifies as ‘Referrer’ and ‘Referred’ will be at sole discretion of Reloy and the same shall not be challenged/contested by the Referrer and/or the Referred at any point of time.',
                                    'In the event that a referral is submitted twice from different Referrers, then the first referral received will be eligible for the Reward (subject to other applicable terms herein) and shall not be disputed by the other Referrers.',
                                    'Referrals are not valid where the Referrer and the Referred party are one and the same person.',
                                    'Referrals will be valid for three months from the date a person/party is ‘Referred’ unless such person/party has paid any booking amount to Godrej Properties within the said period of three months.',
                                    'By accepting our terms and conditions, both Referrer and Referred represent that they have the authority to disclose the name and details of the person or business to Godrej Properties.',
                                    'Subject to the terms mentioned herein, the reward will be paid to the Referrer within 90 (ninety) days from the date of registration of the agreement for sale by the Referred party. The reward will be in the form of gift vouchers/cards having value and a validity period with applicable terms and conditions.',
                                    'This Referral Reward Program cannot be clubbed with any other offer /scheme, whether run by Godrej Properties or otherwise.',
                                    'Customers who are employees of working either in marketing, sales or CRM departments/team and employees of Reloy will not be eligible for participating in this Referral Reward Program and accordingly shall not be entitled for any reward.',
                                    'Reloy will not be liable for any unforeseen delay/deficiency/defect in any product or services disbursed as rewards by it or on account of any dispute relating to right to utilize the product or services subsequent to disbursement of the same.',
                                    'The terms and conditions of this program are subject to change at any time. Reloy will intimate the same to the Referrer. This program may be terminated at the discretion of Reloy.',
                                    'All disputes shall be subject to exclusive jurisdiction of Mumbai courts.'
                                ],
                                listClassName: `text-black text-[16px] xl:text-[16.5px] 2xl:text-[17px] leading-[19px] xl:leading-[20px] 2xl:leading-[22px] ${
                                    getFont('GODREJ').body
                                }`,
                                classNameContainer: 'px-16 mt-6'
                            },
                            {
                                type: 'CUSTOM',
                                className: 'z-10 px-16',
                                components: [
                                    {
                                        type: 'TEXT',
                                        content:
                                            'By referring the customer has provided the consent to receive messages, emails and any other form of communication from Reloy and/or Godrej Properties, for the purposes of the programs and offers on the Reloy App. The customer agrees to the terms and conditions on',

                                        className: `${
                                            getFont('GODREJ').body
                                        } inline text-black text-[17px] leading-[22px] `
                                    },
                                    {
                                        type: 'LINK',
                                        url: 'https://reloy.co/terms-conditions/',
                                        content:
                                            'reloy.co/terms-and-conditions ',
                                        className: `inline ml-1 text-primary text-[16px] xl:text-[16.5px] 2xl:text-[17px] leading-[19px] xl:leading-[20px] 2xl:leading-[22px] ${
                                            getFont('GODREJ').body
                                        }`
                                    },

                                    {
                                        type: 'TEXT',

                                        content: 'and privacy policy on',
                                        className: `inline ml-1 text-black text-[16px] xl:text-[16.5px] 2xl:text-[17px] leading-[19px] xl:leading-[20px] 2xl:leading-[22px] ${
                                            getFont('GODREJ').body
                                        } `
                                    },
                                    {
                                        type: 'LINK',
                                        url: 'https://reloy.co/privacy-policy/',
                                        content: 'reloy.co/privacy-policy ',
                                        className: `inline-block ml-1 text-primary text-[16px] xl:text-[16.5px] 2xl:text-[17px] leading-[19px] xl:leading-[20px] 2xl:leading-[22px] ${
                                            getFont('GODREJ').body
                                        }`
                                    },

                                    {
                                        type: 'TEXT',
                                        content:
                                            'laid out by Loyalie IT-Solutions Pvt. Ltd.In the event the customer wishes to withdraw their consent, they may send an email with the subject “Cancel Reloy Registration’ on the following email id',
                                        className: `inline ml-1 text-black text-[16px] xl:text-[16.5px] 2xl:text-[17px] leading-[19px] xl:leading-[20px] 2xl:leading-[22px] ${
                                            getFont('GODREJ').body
                                        } `
                                    },
                                    {
                                        type: 'LINK',
                                        url: 'mailto:support@reloy.co',
                                        content: 'support@reloy.co',
                                        className: `inline ml-1 text-primary text-[16px] xl:text-[16.5px] 2xl:text-[17px] leading-[19px] xl:leading-[20px] 2xl:leading-[22px] ${
                                            getFont('GODREJ').body
                                        }`
                                    },
                                    {
                                        type: 'TEXT',
                                        content:
                                            'Please note that upon consent being withdrawn, the customer’s Reloy account will be deleted.',
                                        className: `inline ml-1 text-black text-[16px] xl:text-[16.5px] 2xl:text-[17px] leading-[19px] xl:leading-[20px] 2xl:leading-[22px] ${
                                            getFont('GODREJ').body
                                        } `
                                    }
                                ]
                            },
                            {
                                type: 'TEXT',
                                content:
                                    'Terms and Conditions for Ambassador of the Month Contest – Godrej Properties Limited (GPL)',
                                className: `mt-7 tracking-wide text-black px-16 text-[17px] md:text-[17px] ${
                                    getFont('GODREJ').heading
                                }`
                            },
                            {
                                type: 'TEXT',
                                content: '1. Eligibility:',
                                className: `mt-4 tracking-wide text-black px-16 text-[17px] md:text-[17px] ${
                                    getFont('GODREJ').heading
                                }`
                            },
                            {
                                type: 'LIST',
                                listStyle: 'list-disc',

                                points: [
                                    'The ‘Ambassador of the Month’ Contest is open to members of Godrej Ambassador (“Godrej”) who refer a new customer to GPL, pursuant to which there is a successful closure of the referral and sale of a unit in the relevant GPL project during the relevant month. Only referrals submitted online on the Godrej Ambassador platform qualify for the chance to win the lucky draw.',
                                    'Participants acknowledge that the terms of the contest are subject to all terms and conditions applicable for use of the Godrej Ambassador program laid out by Loyalie IT-Solutions Pvt. Ltd, Godrej Properties Limited mobile app, and/ or the referral program.',
                                    'Participants must be Indian citizens, residing in an Indian state where prize competitions are permitted, of legal age, competent to contract and comply with all applicable laws and regulations.',
                                    'Residents of Tamil Nadu is excluded from participation in this contest.',
                                    'Employees and family members of Godrej Properties Ltd and Loyalie IT Solutions Pvt Ltd are not eligible for the contest.'
                                ],
                                listClassName: `text-black text-[16px] xl:text-[16.5px] 2xl:text-[17px] leading-[19px] xl:leading-[20px] 2xl:leading-[22px] ${
                                    getFont('GODREJ').body
                                }`,
                                classNameContainer: 'px-16 mt-2'
                            },
                            {
                                type: 'TEXT',
                                content: '2. Contest Period:',
                                className: `mt-4 tracking-wide text-black px-16 text-[17px] md:text-[17px] ${
                                    getFont('GODREJ').heading
                                }`
                            },
                            {
                                type: 'LIST',
                                listStyle: 'list-disc',

                                points: [
                                    'The contest period will commence on July 19th. Referrals submitted online will be counted until August 15th, 2024, and only successful closure of referrals made by August 31st, 2024, will be considered valid.'
                                ],
                                listClassName: `text-black text-[16px] xl:text-[16.5px] 2xl:text-[17px] leading-[19px] xl:leading-[20px] 2xl:leading-[22px] ${
                                    getFont('GODREJ').body
                                }`,
                                classNameContainer: 'px-16 mt-2'
                            },
                            {
                                type: 'TEXT',
                                content: '3. How to Enter:',
                                className: `mt-4 tracking-wide text-black px-16 text-[17px] md:text-[17px] ${
                                    getFont('GODREJ').heading
                                }`
                            },
                            {
                                type: 'LIST',
                                listStyle: 'list-disc',

                                points: [
                                    'To participate, eligible customers must refer a new customer to any project of GPL.',
                                    'Each successful referral resulting in the sale of a unit in the GPL project, before 31st August 2024 stands a chance to win the lucky prize.'
                                ],
                                listClassName: `text-black text-[16px] xl:text-[16.5px] 2xl:text-[17px] leading-[19px] xl:leading-[20px] 2xl:leading-[22px] ${
                                    getFont('GODREJ').body
                                }`,
                                classNameContainer: 'px-16 mt-2'
                            },
                            {
                                type: 'TEXT',
                                content: '4. Selection of Winning Entry:',
                                className: `mt-4 tracking-wide text-black px-16 text-[17px] md:text-[17px] ${
                                    getFont('GODREJ').heading
                                }`
                            },
                            {
                                type: 'LIST',
                                listStyle: 'list-disc',

                                points: [
                                    "Based on India's final medal count in the international sports event being held in Paris in July and August of 2024  the referral prize and the number of lucky winners will be determined at the end of 31st August 2024. Participants will not be entitled to challenge the selection of the winner by GPL.",
                                    // 'The winners will be decided basis of successsful referrals done at Godrej Properties Ltd projects. A successful referral is one where the referred person books  where the booking amount has been lised by GPL and its subsidiaries/ associated companies.',
                                   'The lucky draw winners will be declared in the 1st week of Sep, customers who have had the highest value (BV) of successful referrals will be amongst the winners. The winning prize will be basis the number of medals Team India wins at the Olympics.  Eg: India wins 15 medals, so the prizes to grab will be 5 trips to Finland and 10 iPhone 15 Pro i.e 15 winners. Now the winners will be decided basis the total BV of successful referrals. Suppose we have 100 successful referrals starting from the highest BV in the descending order 15 winner will be declared. In case of a tie between two customers, no of successful referrals contributing to the BV will be seen e.g 3 successful reference constituting to BV vs 4 successful references with the customer giving more successful references winning.',
                                   'The disqualification of any entries is entirely at Reloy’s discretion'
                                ],
                                listClassName: `text-black text-[16px] xl:text-[16.5px] 2xl:text-[17px] leading-[19px] xl:leading-[20px] 2xl:leading-[22px] ${
                                    getFont('GODREJ').body
                                }`,
                                classNameContainer: 'px-16 mt-2'
                            },
                            {
                                type: 'TEXT',
                                content: '5. Claiming Prizes',
                                className: `mt-4 tracking-wide text-black px-16 text-[17px] md:text-[17px] ${
                                    getFont('GODREJ').heading
                                }`
                            },
                            {
                                type: 'LIST',
                                listStyle: 'list-disc',

                                points: [
                                    'The lucky draw winner will be announced on the first week of September 2024.',
                                    'The selected winners will be notified via the contact information provided to Reloy or on the Godrej Properties Limited app.',
                                    'Winners are required to claim their prize by responding to the notification and providing their due cooperation for delivery of the prize. Prizes cannot be substituted for cash, or for other goods or benefits. Prizes cannot be delivered to any third party. ',
                                    'Participants may be required to execute additional documentation for claiming the prize.',
                                    'Participants shall be responsible for all taxes and other incidental costs, expenses, and charges in relation to receiving the prize.',
                                    'Prizes will be delivered only within India at the time and in the mode determined by GPL, in its sole discretion.',
                                    'The lucky winners will receive economy class flight return tickets to Helsinki, Bali or Goa from any major city in India (BOM, CCU, BLR & DEL).',
                                    'The hotel cost will be for 6 nights capped at a maximum value of Rs. 15,000/- per night. This will be one room to be shared between 2 people.',
                                    'Costs of visa, travel within Finland, food, local transport is not included in the above.',
                                    "The prize includes accommodation for one additional person. Any other additional persons added will be at the customer's expense. Any extra days beyond the trip's duration will also incur costs borne by the customer. Exclusions from the trip inclusions may apply.",
                                    'The prize includes accommodation at a hotel with breakfast provided, along with activities. Additional expenses such as meals outside of breakfast and any incidental charges are not covered.',
                                    'If a winner cannot be reached / does not respond within seven (7) days after the notification of the winning entries, an alternative winner may be selected.'
                                ],
                                listClassName: `text-black text-[16px] xl:text-[16.5px] 2xl:text-[17px] leading-[19px] xl:leading-[20px] 2xl:leading-[22px] ${
                                    getFont('GODREJ').body
                                }`,
                                classNameContainer: 'px-16 mt-2'
                            },
                            {
                                type: 'TEXT',
                                content: '6. Privacy and Data Usage:',
                                className: `mt-4 tracking-wide text-black px-16 text-[17px] md:text-[17px] ${
                                    getFont('GODREJ').heading
                                }`
                            },
                            {
                                type: 'LIST',
                                listStyle: 'list-disc',

                                points: [
                                    'Participant data and personal information will be collected, used, and processed by GPL and Loyalie IT-Solutions Private Limited (“Reloy”) in compliance with applicable data protection laws. By participating, participants consent to the collection, use and processing of their data, and also consent to being contacted for the purposes of the contest.',
                                    'Participants are responsible for obtaining all necessary permissions and consent from the referred customers before sharing their details with GPL/ Reloy. GPL and Reloy will have no liability in the event that participants do not obtain proper permissions or consent from the referred customers for sharing their details.'
                                ],
                                listClassName: `text-black text-[16px] xl:text-[16.5px] 2xl:text-[17px] leading-[19px] xl:leading-[20px] 2xl:leading-[22px] ${
                                    getFont('GODREJ').body
                                }`,
                                classNameContainer: 'px-16 mt-2'
                            },
                            {
                                type: 'TEXT',
                                content: '7. Liabilities and Disputes:',
                                className: `mt-4 tracking-wide text-black px-16 text-[17px] md:text-[17px] ${
                                    getFont('GODREJ').heading
                                }`
                            },
                            {
                                type: 'LIST',
                                listStyle: 'list-disc',

                                points: [
                                    'GPL and Reloy shall not be held liable for any losses, claims, or damages suffered by any participant or referred customers during the Contest. Participants agree to indemnify and hold GPL and Reloy harmless from any claims, losses, or damages arising from their participation in the contest.',
                                    'This Agreement shall be governed by and construed in accordance with the laws of India.',
                                    'All disputes arising out of this Agreement shall be subject to the exclusive jurisdiction of the courts in Mumbai'
                                ],
                                listClassName: `text-black text-[16px] xl:text-[16.5px] 2xl:text-[17px] leading-[19px] xl:leading-[20px] 2xl:leading-[22px] ${
                                    getFont('GODREJ').body
                                }`,
                                classNameContainer: 'px-16 mt-2'
                            },
                            {
                                type: 'TEXT',
                                content: '8. Publicity',
                                className: `mt-4 tracking-wide text-black px-16 text-[17px] md:text-[17px] ${
                                    getFont('GODREJ').heading
                                }`
                            },
                            {
                                type: 'LIST',
                                listStyle: 'list-disc',

                                points: [
                                    "GPL reserves the right to use the winners' names and likeness for promotional and marketing purposes without additional consent."
                                ],
                                listClassName: `text-black text-[16px] xl:text-[16.5px] 2xl:text-[17px] leading-[19px] xl:leading-[20px] 2xl:leading-[22px] ${
                                    getFont('GODREJ').body
                                }`,
                                classNameContainer: 'px-16 mt-2'
                            },
                            {
                                type: 'TEXT',
                                content: '9. Termination and Modification:',
                                className: `mt-4 tracking-wide text-black px-16 text-[17px] md:text-[17px] ${
                                    getFont('GODREJ').heading
                                }`
                            },
                            {
                                type: 'LIST',
                                listStyle: 'list-disc',

                                points: [
                                    'GPL reserves the right to terminate the contest at any time without prior notice.',
                                    'GPL reserves the right to amend these terms and conditions, and the changes will be intimated to participants.',
                                    'By participating in the ‘Ambassador of the Month’ Contest, participants hereby agree and give their express consent to these terms and conditions and any decisions made by GPL.'
                                ],
                                listClassName: `text-black text-[16px] xl:text-[16.5px] 2xl:text-[17px] leading-[19px] xl:leading-[20px] 2xl:leading-[22px] ${
                                    getFont('GODREJ').body
                                }`,
                                classNameContainer: 'px-16 mt-2'
                            }
                        ]
                    },

                    { type: 'RAZORPAY_TERMS', classNameContainer: 'px-16 mt-6' }
                ]
            }
        ]
    },
    myProfile: {
        allowOnboarding: false
    },
    footer: {
        footerLayout: 'Footer1',
        containerClassName:
            'bg-primary xl:px-16 px-8 py-5 text-gray-100 border-t-4 border-secondary',
        footerHeading: 'corporate office',
        footerHeadingClassName: `text-[18px] xl:text-[20px] 2xl:text-[22px] uppercase text-white ${
            getFont('GODREJ').heading
        } `,
        clientAddress:
            ' Unit No. 5C, 5th Floor, Godrej One, Pirojshanagar, Vikhroli East, Mumbai Maharashtra 400079.',
        clientAddressClassName: `text-[16px] xl:text-[16.5px] 2xl:text-[17px] leading-[19px] xl:leading-[20px] 2xl:leading-[22px] pt-3 text-white ${
            getFont('GODREJ').body
        }`,
        contactUsClassName: ` text-white  text-left pt-4 ${
            getFont('GODREJ').body
        }`,
        contactUsPath: '/contact-us',
        termsAndConditionsClassName: ` text-white pt-4 ${
            getFont('GODREJ').body
        }`,
        termsAndConditionsPath: '/terms-and-conditions',
        footerContent1:
            'This is to inform user / customer(s) that www.godrejproperties.com, www.godrejthetrees.com & www.godrej.com are the only official websites of Godrej Properties Limited (“Company”). User/Customer(s) are cautioned and advised not to rely upon any information stated on any other websites which may appear to be similar to the company’s official website, including containing company’s logo / brand name. The information contained in such websites may be misleading/false and user/customer(s) may suffer loss if they rely on such information. In the event user/customer(s) come across any such websites similar to company’s official website containing its brand name/logo or any other information, then kindly contact and inform us on partnerconnect@godrejproperties.com in order to enable the Company to take necessary action.',
        footerContent1ClassName: `text-[12px] lg:text-[12px] xl:text-[12px] leading-[18px] mt-2 text-white  ${
            getFont('GODREJ').body
        }`,
        footerContent2:
            'Please ensure that you deal with only RERA registered real estate agents (“Registered Real Estate Agents”) whose name appear as a real estate agent under the project name on the RERA web site. If you have received any unsolicited calls or text messages in breach of the regulatory norms or from agents not registered with us, please report such instances to us on partnerconnect@godrejproperties.com for our further investigation.',

        footerContent2ClassName: `text-[10px] leading-[18px] pt-4 pb-4 lg:pb-5 text-white  ${
            getFont('GODREJ').body
        }`,
        loyalieFooterContent:
            ' Reloy is run by Loyalie IT-Solutions Pvt. Ltd. This referral program is run by Loyalie IT Solutions Pvt. Ltd. and the referral payout will be processed by them. Loyalie IT Solutions Pvt. Ltd. is registered as a Real Estate Agent under RERA in Maharashtra MAHARERA NO - A51900010267 | Haryana RERA No. - HRERA-PKL-REA-239-2020 | Tamil Nadu RERA NO - TN/Agent/0092/2023 | Karnataka RERA NO-PRM/KA/RERA/1251/309/AG/180524/000935 | West Bengal RERA NO – HIRA/A/KOL/2019/000350 |Uttar Pradesh RERA NO – UPRERAAGT22593 | Gujarat RERA No: AG/GJ/AHMEDABAD/AHMEDABAD CITY/AUDA/AA01891/090327R1 | Delhi RERA Application Reference No: 22-3754-2891 | Goa RERA NO – AGGO02210817 | Telangana RERA NO – A02500001321 |maharera.mahaonline.gov.in | haryanarera.gov.in | www.tnrera.in | rera.karnataka.gov.in | http://hira.wb.gov.in | https://www.up-rera.in | https://gujrera.gujarat.gov.in | https://rera.delhi.gov.in | https://rera.goa.gov.in | http://rera.telangana.gov.in  ',
        loyalieFooterContentClassName: `lg:pl-4 leading-[17px] text-[10px]  text-white ${
            getFont('GODREJ').body
        }`
    },
    commonComponents: {
        filterTagClassName: `text-black text-[17.px] uppercase ${
            getFont('GODREJ').heading
        }`,
        paginationClass:
            ' text-white disabled:text-[#B5BBC1] disabled:cursor-not-allowed bg-secondary-content border-secondary-content hover:bg-opacity-90 hover:border-secondary-content hover:bg-secondary-content disabled:bg-[#D6D8DB]',
        paginationActivePageColor: 'text-white bg-opacity-80'
    },
    commonComponents: {
        // filterTagClassName: `text-secondary-focus text-extrabold text-[17.px] ${getFont('ARVIND').heading
        //     }`,
        paginationClass:
            ' text-white disabled:text-[#B5BBC1] disabled:cursor-not-allowed bg-secondary-content border-secondary-content hover:bg-opacity-90 hover:border-secondary-content hover:bg-secondary-content disabled:bg-[#D6D8DB]',
        paginationActivePageColor: 'text-white bg-opacity-80'
    },
    referralForm: {
        name: 'Godrej',
        theme: 'godrejTheme',
        color: '#7f7065',
        backgroundImage: godrejBgImage,
        bgOverlay: false,
        linearGradient: {
            degree: '90deg',
            R1: '0',
            G1: '0',
            B1: '0',
            opacity1: '0.6',
            R2: '0',
            G2: '0',
            B2: '0',
            opacity2: '0.6'
        },
        showLoginScreen: true,
        userTypes: {
            length: 2,
            siteVisitor: false,
            siteVisitorHeading1: '',
            siteVisitorHeading2: '',

            bookedCustomer: true,
            bookedCustomerHeading1: '',
            bookedCustomerHeading2: '',

            employee: false,
            employeeHeading1: '',
            employeeHeading2: '',

            salesAdmin: true,
            salesAdminHeading1: 'I am a ',
            salesAdminHeading2: 'Godrej Employee*',

            influencer: false,
            influencerHeading1: '',
            influencerHeading2: ''
        },
        formContent: {
            heading: 'ENRICHING EXPERIENCES',
            headingClass: `self-center text-white sm:mt-0 text-4xl lg:text-5xl  w-[80%] lg:w-full  text-center tracking-widest  ${
                getFont('GODREJ').heading
            }`,
            notification: {
                line1: 'Refer & Share The Benefits With Your Loved Ones',
                line1Class: `self-center text-center text-white font-semibold text-xl lg:text-base 2xl:text-2xl lg:tracking-wider ${
                    getFont('GODREJ').default
                }`,
                line2: 'Introduce your loved ones to their dream abode and earn exclusive benefits',
                line2Class: `self-center text-center text-gray-300 mt-1  text-xs sm:text-md lg:text-xs 2xl:text-md ${
                    getFont('GODREJ').default
                }`,
                line3: 'FOR BEING A FRIEND INDEED',
                line3Class: `self-center  text-center  text-primary font-semibold tracking-wider mt-1  text-xl sm:text-4xl lg:text-xl 2xl:text-3xl ${
                    getFont('GODREJ').default
                }`
            },
            loginButton:
                'w-[50%] lg:w-[35%] 2xl:w-[30%] text-white border-4 font-semibold'
        },
        borderUrl:
            "data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' fill='none'%3E%3Cstyle%3Epath%7Banimation:stroke 5s infinite linear;%7D@keyframes stroke%7Bto%7Bstroke-dashoffset:776;%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='100%25' stop-color='white'/%3E%3C/linearGradient%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke='%237f7065' stroke-width='7' fill='none' /%3E%3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='7' stroke-dasharray='388'/%3E%3C/svg%3E",
        hideRelation: true,
        leadEmailMandatory: false,
        isDirectTAndCLink: false,
        influencerLinkActive: false,
        termsAndConditionsLink: '/godrej-website/terms-and-conditions',
        customLink: `/godrej-website/projects/detail`,
        employeeDisclaimer: false,
        employeeDisclaimerContent: '',
        salesAdminDisclaimer: true,
        salesAdminDisclaimerContent: '',
        footerDisclaimer: true,
        footerDisclaimerContent: '',
        showProjectsInDropdown: true
    }
};
export default config;
