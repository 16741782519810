import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAxios from '../../../../Hooks/use-axios';
import { winnreAuth } from '../../../../utils/axios';

const useData = (dealId) => {
    const [deal, setDeal] = useState('');
    const [bundleDeals, setBundleDeals] = useState([]);
    const [bundleDealDetails, setBundleDealDetails] = useState([]);
    const { isLoading: loadingBundleDeals, sendRequest: fetchBundleDeals } = useAxios();
    const [similarOffers, setSimilarOffers] = useState([]);
    const [topOffers, setTopOffers] = useState([]);
    const [sameBrandOffers, setSameBrandOffers] = useState([]);
    const [faultyDeal, setFaultyDeal] = useState(false)
    const {isLoading: loadingDeal,sendRequest: fetchDealDetails} = useAxios();
    const {isLoading: loadingSimilarDeals, sendRequest: fetchSimilarDeals } =
        useAxios();

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    const getDealDetails = () => {
        fetchDealDetails(
            {
                isEncryptedResponse:isAuthenticated? true:false,
                url:isAuthenticated? `/winnre/user/deals/${dealId}`:`/cms_service/deals/${dealId}`,
                method: 'get'
            },
            (data) => {
                console.log('deal details', data);
                if(data?.status == 'FAILED'){
setFaultyDeal(true)
return
                }
                setDeal(data);
                if(isAuthenticated){
               if (data?.category?.id == '8') {
                    getBundleDealsWithSameTag(data?.tags[0]?.id);
                }
                getSimilarOffersByCategory(
                    data.id,
                    data?.category?.id,
                    data?.tags[0]?.id
                );
                getSameBrandOffers(data.id, data.vendor.id);
                }
            
            }
        );
    };
    const getBundleDealsWithSameTag = (id) => {
        // console.log("tag id", id)
        fetchBundleDeals(
            {
                isEncryptedResponse: isAuthenticated ? true : false,
                url: `/winnre/user/deals/filter-v2?&status=PUBLISHED&isAscending=false&sortBy=priority&categoryList=8&pageSize=10&tagList=${id}`,
                method: 'get',
                isFormData: true
            },
            (res) => {
                // console.log("bundle deal", res?.content)
                setBundleDeals(res?.content);
                const bundleIds = res?.content.map((offer) => offer.id);
                let apiCall = Promise.all(
                    bundleIds?.map((id) =>
                        winnreAuth({
                            method: 'GET',
                            url: `/winnre/user/deals/${id}`
                        })
                    )
                );
                apiCall.then((res) => {
                    setBundleDealDetails(res);
                });
            }
        );
    };

    const getSimilarOffersByCategory = (dealID, categoryID, tagID) => {
        console.log(dealID, categoryID, tagID)
        fetchSimilarDeals(
            {
                isEncryptedResponse: isAuthenticated ? true : false,
                url: `/winnre/user/deals/filter-v2?status=PUBLISHED&isAscending=false&sortBy=priority&categoryList=${categoryID}&pageSize=10${
                    categoryID == 8 ? `&tagList=${tagID}` : ''
                }`,
                method: 'get',
                isFormData: true
            },
            (res) => {
                setSimilarOffers(
                    res?.content.filter((deal) => deal.id !== dealID)
                );
            }
        );
    };

    const getOffersOfTheMonth = () => {
        winnreAuth({
            method: 'GET',
            url: '/winnre/user/deals/filter-v2?status=PUBLISHED&isAscending=false&sortBy=priority&categoryList=1,2,3,5&pageSize=10&tagList=52'
        }).then((data) => {
            setTopOffers(data?.content || []);
        });
    };

    const getSameBrandOffers = (dealID, vendorID) => {
        winnreAuth({
            method: 'GET',
            url: `/winnre/user/deals/filter-v2?status=PUBLISHED&isAscending=false&sortBy=priority&pageSize=10&vendorIds=${vendorID}`
        }).then((data) => {
            setSameBrandOffers(
                data?.content.filter((deal) => deal.id !== dealID) || []
            );
        });
    };

    useEffect(() => {
           getDealDetails();
        if (isAuthenticated) {
            getOffersOfTheMonth();
        }
    }, [dealId, isAuthenticated]);

    return {
        deal,
        loadingDeal,
        similarOffers,
        topOffers,
        sameBrandOffers,
        bundleDeals,
        bundleDealDetails,
        faultyDeal
    };
};

export default useData;
