import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { winnreAuth } from '../../../../../../utils/axios';
import Modal from '../../../Modal';
import PhoneInput from 'react-phone-input-2';
import TiUserAddOutline from '@meronex/icons/ti/TiUserAddOutline';
import {
    LOYALTY,
    RE_EMAIL,
    RE_NAME,
    rawCountries
} from '../../../../../../constants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import CustomModal from '../../../../../../Components/Organisms/Modal';
import EiCloseO from '@meronex/icons/ei/EiCloseO';
import { getConfig } from '../../../../../../utils/config';
import { scrollToTargetAdjusted } from '../../../../../../utils/ui';
const valueToSend = { message: 'OPEN_CONTACT' };

const StepTwo = ({
    setStep,
    enquiryCities,
    config,
    userRole,
    changeCurrentStep,
    termsCheckbox,
    setTermsCheckbox,
    loyalty,
    setLoyalty,
    freeText,
    setFreeText,
    RM,
    setRM,
    isInputFocused,
    setInputFocused,
    onChange,
    validator,
    mobile,
    setMobileIsTouched,
    mobileHasError,
    mobileNumberIsValid,
    referredLeadName,
    referredLeadNameHasError,
    referredLeadNameChangedHandler,
    referredLeadNameBlurHandler,
    referredLeadNameIsValid,
    referredLeadEmail,
    referredLeadEmailIsValid,
    referredLeadEmailChangedHandler,
    referredLeadEmailHasError,
    referredLeadEmailBlurHandler,
    comments,
    commentsChangedHandler,
    onFormValidityChange,
    showModal,
    setShowModal,
    modalMessage,
    setModalMessage,
    referHandler,
    projectToRefer,
    setReferredLeadNameIsTouched,
    setReferredLeadEmailIsTouched
}) => {
    const history = useHistory();
    const [rmNameList, setRmNameList] = useState([]);
    // const [formIsValid, setFormIsValid] = useState(false);
    const [showSubmitPopup, setShowSubmitPopup] = useState(false);

    // const [showNameError, setShowNameError] = useState(false);
    // const [showMobileError, setShowMobileError] = useState(false);

    const config1 = getConfig();

    const isInternalWebview = useSelector(
        (state) => state.webView.internalWebview
    );

    const [isMobileView, setIsMobileView] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        window.onContactChangeFromNativeWebview = (message) => {
            const data = JSON.parse(message);
            const country = rawCountries.find(
                (c) => c[3] === String(data.country)
            );
            onChange(
                data.country + data.mobile,
                country
                    ? {
                          name: country[0],
                          dialCode: country[3],
                          countryCode: country[2],
                          format: country[4]
                      }
                    : {
                          name: 'India',
                          dialCode: '91',
                          countryCode: 'in',
                          format: '+.. .....-.....'
                      }
            );
        };

        return () => {
            window.removeEventListener('resize', handleResize);
            window.onContactChangeFromNativeWebview = null;
        };
    }, []);

    const removeCountryCode = (value) => {
        return value.replace(/^\+\d{1,3}/, '');
    };

    useEffect(() => {
        if (showSubmitPopup) {
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
        }
    }, [showSubmitPopup]);

    useEffect(() => {
        getRMNameList();
    }, [enquiryCities]);

    const getRMNameList = () => {
        setRM(null);
        winnreAuth({
            method: 'get',
            url: `/winnre/user/rm/names`
        })
            .then((res) => {
                console.log(res);
                setRmNameList(
                    res?.map((obj) => {
                        return {
                            id: obj?.id,
                            name: obj?.name
                        };
                    })
                );
            })
            .catch(() => {
                setModalMessage('Could not load RM names. Please try again!');
                setShowModal(true);
            });
    };
    const loyaltyHandler = (val) => {
        setLoyalty(val.value);
    };

    const handleOnChange = () => {
        setTermsCheckbox(!termsCheckbox);
    };

    const rmNameHandler = (val) => {
        setRM({
            rmId: val.value,
            rmName: val.label
        });
    };
    const backClickHandler = (e) => {
        e.preventDefault();
        if (userRole === 'EMPLOYEE') {
            if (!config?.userTypes.employee) {
                changeCurrentStep('createOnBehalfForm');
                return;
            }
            changeCurrentStep('userTypeForm');
            return;
        }
        if (userRole === 'RELATIONSHIP_MANAGER') {
            changeCurrentStep('createOnBehalfForm');
            return;
        }
    };

    const showBackButton =
        (userRole === 'RELATIONSHIP_MANAGER' && config?.userTypes.salesAdmin) ||
        (config?.userTypes.employee && config?.userTypes.salesAdmin) ||
        (!config?.userTypes.employee && userRole === 'EMPLOYEE');

    // useEffect(() => {
    //     let IsValid = false;
    //     if (referredLeadNameIsValid && mobileNumberIsValid && termsCheckbox) {
    //         if (loyalty === 'From my RM' && RM?.rmName) {
    //             IsValid = true;
    //         } else if (loyalty !== 'From my RM') {
    //             IsValid = true;
    //         }
    //     }

    //     setFormIsValid(IsValid);
    //     onFormValidityChange(IsValid);
    // }, [
    //     referredLeadNameIsValid,
    //     mobileNumberIsValid,
    //     termsCheckbox,
    //     loyalty,
    //     RM?.rmName,
    //     onFormValidityChange
    // ]);

    let formIsValid = false;
    if (
        referredLeadNameIsValid &&
        mobileNumberIsValid &&
        projectToRefer &&
        termsCheckbox
    ) {
        if (config?.leadEmailMandatory && referredLeadEmailIsValid) {
            formIsValid = true;
        } else if (!config?.leadEmailMandatory) {
            formIsValid = true;
        }
        if (loyalty === 'From my RM' && !RM?.rmName) {
            formIsValid = false;
        }
    }

    console.log('RM', RM?.rmName);

    // useEffect(() => {
    //     if (mobileNumberIsValid) {
    //         setShowMobileError(false);
    //     }
    //     if (referredLeadNameIsValid) {
    //         setShowNameError(false);
    //     }
    // }, [mobileNumberIsValid, referredLeadNameIsValid]);
    const checkFormValidity = () => {
        if (!referredLeadNameIsValid) {
            setReferredLeadNameIsTouched(true);
            scrollToTargetAdjusted('nameInput');
            return;
        }
        if (!mobileNumberIsValid) {
            setMobileIsTouched(true);
            scrollToTargetAdjusted('mobileInput');
            return;
        }

        if (config?.leadEmailMandatory && !referredLeadEmailIsValid) {
            setReferredLeadEmailIsTouched(true);
            scrollToTargetAdjusted('emailInput');
        }
    };
    console.log('form is valid', formIsValid);
    const handleSubmit = () => {
        if (!formIsValid) {
            checkFormValidity();
            // setShowNameError(!referredLeadName);
            // setReferredLeadNameIsTouched(true)
            // setMobileIsTouched(true)
            // // setShowMobileError(!mobile);
            // if (!mobileNumberIsValid || !referredLeadNameIsValid) {
            //     scrollToTargetAdjusted('required');
            // }
        } else {
            setShowSubmitPopup(true);
        }
    };

    return (
        <div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-7 border-gray-300 ">
                <div id="nameInput">
                    <div className=" mt-2 py-2">
                        <h2>
                            Referral Name
                            <span className="text-red-400"> *</span>
                        </h2>
                        <input
                            type="text"
                            className=" w-full mt-1 text-black h-12 lg:h-10 xl:h-12 rounded px-2 py-1 border-[#BFBFBF] focus:border-[#BFBFBF] outline-none border-[1px] placeholder-[#000000B3]"
                            onChange={referredLeadNameChangedHandler}
                            onBlur={referredLeadNameBlurHandler}
                            value={referredLeadName}
                            autoComplete="off"
                        />
                        {referredLeadNameHasError && (
                            <p className="text-left text-sm font-semibold text-red-500">
                                Please enter a valid Name
                            </p>
                        )}
                    </div>
                    
                </div>
                
               

                <div id="mobileInput" className=" lg:mt-2 py-2">
               
                    <h2>
                        Phone Number
                        <span className="text-red-400"> *</span>
                    </h2>
                    <div className="h-12 lg:h-10 xl:h-12">
                        <PhoneInput
                            country={'in'}
                            containerClass={`mt-1 bg-white text-black rounded-md border-[#BFBFBF] ${
                                isInputFocused
                                    ? 'border-primary border-[1px]'
                                    : 'border-gray-100'
                            }`}
                            countryCodeEditable={false}
                            containerStyle={{
                                width: '100%',
                                height: '100%'
                            }}
                            inputStyle={{
                                width: '100%',
                                height: '100%',
                                fontSize: '16px'
                            }}
                            buttonStyle={{ backgroundColor: 'white' }}
                            isValid={validator}
                            onChange={onChange}
                            // onClick={() => {
                            //     if (isInternalWebview) {
                            //         window?.webkit?.messageHandlers?.jsMessageHandler.postMessage(
                            //             valueToSend
                            //         );
                            //         window?.JSBridge?.showMessageInNative(
                            //             'OPEN_CONTACT'
                            //         );
                            //     }
                            // }}
                            value={mobile}
                            enableSearch
                            onBlur={() => {
                                setMobileIsTouched(true);
                                setInputFocused(false);
                            }}
                            onFocus={() => setInputFocused(true)}
                            inputClass="w-full mt-1 text-grey-900 rounded px-2 py-1 border-[#BFBFBF] focus:border-[#BFBFBF] outline-none border-[1px] placeholder-[#000000B3]"
                        />

                        
                    </div>
                    {mobileHasError && (
                            <div>
                                <p className="text-left text-sm font-semibold text-red-500  ">
                                    Please enter a valid Number
                                </p>
                            </div>
                        )}
                        {isInternalWebview && (
                    <div className="flex flex-col items-center justify-center ">
                            <>
                        <div className="relative my-10 w-1/2">
                    <hr className="h-[2px] bg-[#D1D1D3] text-[#D1D1D3] w-full" />
                    <div className="absolute left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white text-gray-400 font-semibold rounded-full border-2 border-[#D1D1D3] inline p-2">
                        OR
                    </div>
                </div>

                            <button
                                className="bg-primary text-white py-2 px-4 rounded-lg"
                                
                                onClick={() => {
                                    if (isInternalWebview) {
                                        // const ContactNumber =
                                        //     removeCountryCode(valueToSend);
                                        window?.webkit?.messageHandlers?.jsMessageHandler.postMessage(
                                            valueToSend
                                        );
                                        window?.JSBridge?.showMessageInNative(
                                            'OPEN_CONTACT'
                                        );
                                    }
                                }}
                            >
                              
                               Select from Contacts
                            </button>
                            </>
                    </div>
                         )} 

                </div>

                <div id="emailInput" className="mt-1 lg:mt-0 py-2">
                    <h2>
                        Email
                        {config?.leadEmailMandatory && (
                            <span className="text-red-400"> *</span>
                        )}
                    </h2>
                    <input
                        type="email"
                        className="w-full text-black mt-1 text-grey-900 h-12 lg:h-10 xl:h-12 rounded px-2 py-1 border-[#BFBFBF] focus:border-[#BFBFBF] outline-none border-[1px] placeholder-[#000000B3]"
                        onChange={referredLeadEmailChangedHandler}
                        onBlur={referredLeadEmailBlurHandler}
                        value={referredLeadEmail}
                        autoComplete="off"
                    />
                    {config?.leadEmailMandatory &&
                        referredLeadEmailHasError && (
                            <p className="text-left text-sm font-semibold text-red-500">
                                Please enter a valid Email
                            </p>
                        )}
                </div>
                <div className="mt-2 lg:mt-0 py-2">
                    {!config?.hideLoyalty && (
                        <>
                            <div className="flex  flex-row items-center md:whitespace-nowrap">
                                <h2>
                                    How did you hear about the loyalty program
                                </h2>
                            </div>

                            <div className="h-12 lg:h-10 xl:h-12">
                                <Select
                                    placeholder="Select option"
                                    options={LOYALTY?.map((loyalty) => ({
                                        label: loyalty,
                                        value: loyalty
                                    }))}
                                    onChange={(val) => {
                                        loyaltyHandler(val);
                                    }}
                                    value={
                                        loyalty
                                            ? {
                                                  value: loyalty,
                                                  label: loyalty
                                              }
                                            : null
                                    }
                                    styles={{
                                        container: (provided, state) => ({
                                            ...provided,
                                            width: '100%',
                                            color: 'black',
                                            height: '100%'
                                        }),

                                        control: (styles, state) => ({
                                            ...styles,
                                            opacity: '0.95',
                                            height: '100%',
                                            color: 'black',
                                            border: `1px solid #BFBFBF `,
                                            boxShadow: 'none'
                                        }),
                                        placeholder: (styles) => ({
                                            ...styles,
                                            color: '#000000B3',
                                            fontWeight: 300
                                        })
                                    }}
                                    className="text-grey-900 placeholder-[#000000B3] font-semibold bg-white text-base text-left rounded-md mt-1"
                                />
                            </div>
                        </>
                    )}
                </div>
                {loyalty === 'Other' ? (
                    <div className=" mt-2 lg:mt-0 py-2">
                        <h2>If Other,</h2>
                        <input
                            type="text"
                            className="w-full text-black mt-3  mb-3 text-grey-900 h-12 lg:h-10 xl:h-12 rounded px-2 py-1 border-[#BFBFBF] focus:border-[#BFBFBF] outline-none border-[1px] placeholder-[#000000B3]"
                            placeholder="Add your text"
                            onChange={(e) => setFreeText(e.target.value)}
                            value={freeText}
                            autoComplete="off"
                        />
                    </div>
                ) : loyalty === 'From my RM' ? (
                    <div className="mt-2 lg:mt-0 py-2">
                        <h2>
                            RM's Name <span className="text-red-400"> *</span>{' '}
                        </h2>
                        <div className="h-12 lg:h-10 xl:h-12">
                            <Select
                                placeholder="Search here"
                                options={rmNameList?.map((n) => ({
                                    label: n.name,
                                    value: n.id
                                }))}
                                onChange={(val) => {
                                    rmNameHandler(val);
                                }}
                                value={
                                    RM
                                        ? {
                                              value: RM?.rmName,
                                              label: RM?.rmName
                                          }
                                        : null
                                }
                                styles={{
                                    container: (provided, state) => ({
                                        ...provided,
                                        width: '100%',
                                        color: 'black',
                                        height: '100%'
                                    }),

                                    control: (styles, state) => ({
                                        ...styles,
                                        opacity: '0.95',
                                        height: '100%',
                                        color: 'black',
                                        border: `1px solid #BFBFBF `,
                                        boxShadow: 'none'
                                    }),
                                    placeholder: (styles) => ({
                                        ...styles,
                                        color: '#000000B3',
                                        fontFamily: 'sans-serif',
                                        fontStyle: 'italic',
                                        fontWeight: 300
                                    })
                                }}
                                className="text-grey-900 placeholder-[#000000B3] font-semibold bg-white text-base text-left rounded-md mt-3 "
                            />
                        </div>
                        {loyalty === 'From my RM' && !RM?.rmName && (
                            <p className="text-left text-sm font-semibold text-red-500">
                                Please select an RM name
                            </p>
                        )}
                    </div>
                ) : null}
            </div>

            <div className="w-full mt-2 lg:mt-0 py-2">
                <h2>Comments</h2>
                <textarea
                    type="text"
                    className=" text-black w-full mt-1 text-grey-900 h-16 lg:h-12 xl:h-16 rounded px-2 py-1 border-[#BFBFBF] focus:border-[#BFBFBF] outline-none border-[1px] placeholder-[#000000B3]"
                    onChange={commentsChangedHandler}
                    value={comments}
                />
                <div className="flex mt-3">
                    <input
                        type="checkbox"
                        checked={termsCheckbox}
                        onChange={handleOnChange}
                        className="checkbox-sm bg-white rounded-md border-gray-100 checked:bg-blue-600"
                        autoComplete="off"
                    />
                    <p
                        className={`${
                            config?.customConfig?.headingColor ||
                            'text-black font-semibold'
                        } mx-2 text-sm `}
                    >
                        <span className="text-black">I agree to the </span>

                        <span
                            onClick={() => {
                                history.push('/terms-and-conditions');
                            }}
                            className="font-semibold text-primary underline cursor-pointer"
                        >
                            {`Terms & Conditions`}
                        </span>
                    </p>
                </div>
            </div>

            {userRole !== 'RELATIONSHIP_MANAGER' && userRole !== 'EMPLOYEE' && (
                <div className={'flex  w-full  mt-8 lg:mt-6 justify-between'}>
                    <button
                        className=" px-8 py-2 md:px-8 mb-5 mt-auto  bg-gray-400 rounded-md font-semibold text-white hover:bg-gray-focus disabled:opacity-90 disabled:bg-gray-400 disabled:hover:bg-gray-400 disabled:cursor-not-allowed"
                        onClick={(e) => setStep((prev) => prev - 1)}
                    >
                        Back
                    </button>

                    <button
                        className="sub-btn  px-6 md:px-6 mb-5 mt-auto text-white disabled:opacity-70 disabled:bg-gray-400  disabled:cursor-not-allowed "
                        //  onClick={() => {
                        //     if(!mobile){
                        //         scrollToTargetAdjusted('Phone_Num');
                        //     }
                        //     setShowSubmitPopup(true);
                        // }}
                        onClick={handleSubmit}
                        // disabled={!formIsValid}
                    >
                        Submit
                    </button>
                </div>
            )}
            {userRole !== 'RELATIONSHIP_MANAGER' &&
                userRole !== 'EMPLOYEE' &&
                showSubmitPopup && (
                    <CustomModal
                        className={`w-full md:max-w-[75%] lg:max-w-[65%] xl:max-w-[50%] lg:w:-[70%] lg:xl-w-[84] bg-opacity-80`}
                        visible={showSubmitPopup}
                    >
                        <div className="flex flex-col px-5 ml-2 mr-2">
                            <div className="flex justify-between items-center my-4 ">
                                <h1 className="text-lg lg:text-xl  font-bold ">
                                    Confirmation
                                </h1>
                                <EiCloseO
                                    onClick={() => setShowSubmitPopup(false)}
                                    className={` h-7 w-7 sm:h-9 sm:w-10 cursor-pointer   text-gray-500`}
                                />
                            </div>
                            <svg viewBox="0 0 457 2">
                                <defs></defs>
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                        <polygon
                                            class="cls-1"
                                            points="0 1 57.13 0.51 114.25 0.23 228.5 0 342.75 0.23 399.88 0.51 457 1 399.88 1.49 342.75 1.77 228.5 2 114.25 1.76 57.13 1.49 0 1"
                                            fill="#e5e5e5"
                                        />
                                    </g>
                                </g>
                            </svg>

                            <div className="flex gap-5 items-center my-5">
                                <img
                                    variant="square"
                                    loading="lazy"
                                    className={`${
                                        config1?.projects.moduleConfig
                                            ?.QRCodeImage
                                            ? 'h-10 w-10'
                                            : 'h-20 w-28'
                                    } z-10  object-center object-cover rounded-lg`}
                                    // src={projectToRefer?.image}
                                    src={projectToRefer?.image ? projectToRefer.image : config1?.programLogo}
                                    alt="property"
                                />
                                <div className="flex flex-col ">
                                    <p className="text-black font-bold text-base lg:text-lg ">
                                        {projectToRefer?.projectName}
                                    </p>
                                    <p className="text-[#908F95] text-sm sm:text-base italic">
                                        {projectToRefer?.city}
                                    </p>
                                </div>
                            </div>
                            <svg viewBox="0 0 457 2">
                                <defs></defs>
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                        <polygon
                                            class="cls-1"
                                            points="0 1 57.13 0.51 114.25 0.23 228.5 0 342.75 0.23 399.88 0.51 457 1 399.88 1.49 342.75 1.77 228.5 2 114.25 1.76 57.13 1.49 0 1"
                                            fill="#e5e5e5"
                                        />
                                    </g>
                                </g>
                            </svg>
                            <div className="flex flex-wrap justify-between mt-5 ">
                                <div
                                    className={` w-[90%] lg:w-[45%] md:w-[45%] flex flex-col my-2`}
                                >
                                    <p className="text-gray-600 text-base">
                                        Referral Name
                                    </p>
                                    <p className=" text-base lg:text-lg font-bold">
                                        {referredLeadName}
                                    </p>
                                </div>
                                <div
                                    className={` w-[90%] lg:w-[45%] md:w-[45%] flex flex-col  my-2`}
                                >
                                    <p className="text-gray-600 text-base">
                                        Phone Number
                                    </p>
                                    <div className="flex gap-1 items-center">
                                        <p className=" text-base lg:text-lg font-bold">{`+${mobile.slice(
                                            0,
                                            2
                                        )}`}</p>
                                        <p className=" text-base lg:text-lg font-bold">
                                            {mobile.slice(2)}
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={` w-[90%] lg:w-[45%]  md:w-[45%]  flex  flex-col  my-2`}
                                >
                                    <p className="text-gray-600 text-base">
                                        Email
                                    </p>
                                    <p className=" text-base lg:text-lg font-bold">
                                        {referredLeadEmail
                                            ? referredLeadEmail
                                            : 'NA'}
                                    </p>
                                </div>
                                <div
                                    className={` w-[90%] lg:w-[45%]  md:w-[45%]  flex flex-col my-2`}
                                >
                                    <p className="text-gray-600 text-base xl:whitespace-nowrap">
                                        How did you hear about the loyalty
                                        program
                                    </p>
                                    <p className="text-base lg:text-lg font-bold md:whitespace-nowrap">
                                        {loyalty ? loyalty : 'NA'}
                                    </p>
                                </div>
                                <div className={` w-[90%] flex flex-col my-2`}>
                                    <p className="text-gray-600 text-base">
                                        Comments
                                    </p>
                                    <p className="text-base lg:text-lg font-bold whitespace-nowrap">
                                        {comments ? comments : 'NA'}
                                    </p>
                                </div>
                            </div>
                            <div className="flex justify-between items-center mt-4 mb-6">
                                <button
                                    className="text-black px-4 py-3 bg-gray-300 rounded-md"
                                    onClick={() => setShowSubmitPopup(false)}
                                >
                                    Make Changes
                                </button>
                                <button
                                    className="text-white px-4 py-3 bg-primary rounded-md"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        referHandler(event);
                                        setShowSubmitPopup(false);
                                    }}
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </CustomModal>
                )}

            <Modal
                visible={showModal}
                onClick={() => setShowModal(false)}
                content={modalMessage}
            />
        </div>
    );
};

export default StepTwo;
